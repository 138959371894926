import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DragnDropUpload from '../components/ImageDrop/DragnDropUpload';
import ListComponent from '../components/ListComponent/ListComponent';

import LandingPage from '../pages/LandingPage';
import TestOverlay  from '../pages/TestOverlay';
import Login from '../pages/Login'
import SignUp from '../pages/SignUp'
import PassRecover from '../pages/PassRecover'

import LandingPagePublic from '../pages/LandingPagePublic';




//Components
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import MapView from '../components/MapView';
import TestSurvey from '../pages/TestSurvey';
import MySites from '../pages/MySites';
import TestDesignSurvey from '../pages/TestDesignSurvey';
import RegistarMapeo from '../pages/RegisterCode';
import Download from '../pages/Download';
import Admin from '../pages/Admin';
import SubAdmin from '../pages/SubAdmin';
import Usuaarios from '../pages/Usuarios';
import Mapeos from '../pages/Mapeos';
import NewAdmin from '../pages/NewAdmin';
import NewSubAdmin from '../pages/NewSubAdmin';
import Download2 from '../pages/Download2';

const Router = () => {
    return (
      <BrowserRouter>
        <Routes>
        
        <Route path='/' element={<PublicRoute Component={LandingPage} />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/recoverpassword' element={<PassRecover />} />
        <Route path='/maptest' element={<MapView />} />
        <Route path='/testsurvey' element={<TestSurvey />} />
        <Route path='/testdesign' element={<TestDesignSurvey />} />
        {/* <Route path='/usuarios' element={<Usuaarios />} />

        <Route path='/overlay' element={<TestOverlay />} /> */}

        {/* <Route path='/admin' element={<Admin/>} />


        <Route path='/usuarios' element={<Usuaarios />} /> */}




        {/* <Route path='/overlay' element={<TestOverlay />} />
        <Route path='/lugares' element={<LandingPagePublic />} />  
        <Route path='/mis-lugares' element={<MySites />} />   */}


        {/* <Route exact path='/' element={<PrivateRoute Component={TestOverlay} />}>
        <Route path='/lugares' element={<PrivateRoute Component={LandingPagePublic} />} />
          <Route path='/overlay' element={<TestOverlay />} />
          <Route path='/dragtest' element={<ListComponent />} />
          <Route path='/imagetest' element={<DragnDropUpload />} />
          
        </Route> */}
        <Route path='/overlay' element={<PrivateRoute Component={TestOverlay} />} />
        <Route path='/lugares' element={<PrivateRoute Component={LandingPagePublic} />} />  
        <Route path='/mis-lugares' element={<PrivateRoute Component={MySites} />} />  
        <Route path='/download' element={<PrivateRoute Component={Download} />} />
        <Route path='/download2' element={<PrivateRoute Component={Download2} />} />
        <Route path='/admin' element={<PrivateRoute Component={Admin} />} />
        <Route path='/subadmin' element={<PrivateRoute Component={SubAdmin} />} />
        <Route path='/usuarios' element={<PrivateRoute Component={Usuaarios} />} />
        <Route path='/nuevo-mapeo' element={<PrivateRoute Component={RegistarMapeo} />} />
        <Route path='/mapeos' element={<PrivateRoute Component={Mapeos} />} />
        <Route path='/nuevo-admin' element={<PrivateRoute Component={NewAdmin} />} />
        <Route path='/nuevo-subadmin' element={<PrivateRoute Component={NewSubAdmin} />} />
        </Routes>
        
      
      </BrowserRouter>
    );
  }
  
  export default Router;