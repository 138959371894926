import React from 'react'
import DraggableList from './DraggableList'

import CardList from './Card/CardList'

export default function ListComponent(props) {
  const [dataList, setDataList] = React.useState(['Apple', 'Orange', 'Pineapple']) 

    return (
    <div style={{width:"100%"}}>
        
        <DraggableList 
          data={props.selections !== undefined ? props.selections : []}
          setData={props.setSelections !== undefined ? props.setSelections : null}
          renderItemContent={(item) => LessonCard(item)}
          
          >

        </DraggableList>
    </div>
  )
}

const LessonCard = item => <CardList item={item} ></CardList> 
