import React from 'react'
import '../pages/overlay.css'

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton  from '@mui/material/IconButton'
import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';

//Components
import InnerLabel from './InnerLabel';
import NegativeQuestion from './NegativeQuestion';
import PositiveQuestion from './PositiveQuestion';
import ListComponent from './ListComponent/ListComponent';
import DropBox from './ImageDrop/DropBox';

//icons
import CloseIcon from '@mui/icons-material/Close';

//API
import { uploadFilesToServer } from '../api/userAPI'
import { Navigate } from 'react-router-dom';



export default function DesignSurvey(props) {
  const navigate = useNavigate();
    const activeStep = props.activeStep;
    const setActiveStep = props.setActiveStep;
    const setSurveyDisplay = props.setSurveyDisplay
    const [transport, setTransport] = React.useState([]);
    const [radioValue, setRadioValue] = React.useState('');
    const [isPositive, setIsPositive] = React.useState(undefined);
    const [selections, setSelections] = React.useState([]);
    const [description, setDescription] = React.useState('');
    const [fileList, setFileList] = React.useState([]);

    const forwardStep = () => {

        if(activeStep === 2){
            if (radioValue === 'No me gusta' || radioValue === 'Me siento en peligro' || radioValue === 'Me da tristeza'){
              setIsPositive(false)
              //setSelections([])
              
            }else if (radioValue === 'Me gusta' || radioValue === 'Me siento segura/o' || radioValue === 'Me encanta'){
              setIsPositive(true)
              //setSelections([])
            }
          }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        


    }

    const backWardStep = () =>{
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if(activeStep === 2){
            setSelections([])
        }
    }

    // Handlers

    const handleTransports = (event) =>{
        console.log(event.target.checked)
        if(event.target.checked){
          setTransport([...transport, event.target.name])
        }else{
          setTransport((state) => state.filter((item) => item !== event.target.name))
        }
        console.log(transport)
    }

    const handleRadio = (event) =>{
        setRadioValue(event.target.value);
        console.log(radioValue)
       }


    /** On drop callback function to get the images from the dropbox */
    const onDrop = React.useCallback((acceptedFiles) => {
        
        /** Mapping the incoming accepted files and saving them in the file list */
        acceptedFiles.map((list,index) => {
        
        setFileList((prevState) => [
            ...prevState,
            { id: index, path:  list.path, size: list.size, extension: list.type, content:list},
            ])

        });

        
        
    }, []);

    const handleFinish = ()=>{
        //console.log('entra')
        setSurveyDisplay('none')
        setActiveStep(0)
        return navigate('/mis-lugares')
    }


    const deleteFileFromList = (path)=>{
        //Find file index in the list thorugh its path
        var index = fileList.findIndex(e => e.path === path);
        //If index if found, delete the file by slicing the list, this process is done this way because it don't mutates the state
        if(index !== -1){
          
          setFileList([
            ...fileList.slice(0, index),
            ...fileList.slice(index + 1)
          ]);
    
        }
    
      }

      /** Function that handles the upload of the files in the filesList state variable */
      const uploadFiles = async ()=>{
      
        /*
        console.log("entra")
        setErrorExercise(!exerciseSelection)
        setErrorStation(!stationSelection)
        
        if(fileList.length === 0){
          setErrorFiles(true);
          setModalMessage({msg : "Se debe seleccionar al menos un archivo", color: "#ff0000"});
        }else{
          setErrorFiles(false);
        }
        console.log("Error exercise value " + errorExercise)
        console.log("Error station value " + errorStation)
        console.log("Longitud de la lista" + fileList.length)
        console.log("Eror files "+ errorFiles)
        */
        // if(( errorStation === false && errorFiles === false) &&  fileList.length !== 0){
          
          // const formData = new FormData(); //Create a new form-data
        //   const config2 = { headers: { 'Content-Type': 'multipart/form-data' } }; //headers
  
        //   //Iterate through the list and get the value of the content key wich stores the file, then append each file to the form under the upfiles key
          // fileList.map( (f) =>{
            
          //   formData.append('upfiles', f.content);
          // });
          
        // //Append idEstacion and idEjercicio to the form 
        // formData.append('idEstacion', stationSelection);
  
        // if(exerciseSelection == '' || exerciseSelection == null){
        //   formData.append('idEjercicio', null);
        // }else{
        //   formData.append('idEjercicio', exerciseSelection);
        // }
        
  
        
          
        // /** Axios post request to backend server  */
        // /*
        // axios.post('https://127.0.0.1:4000',  formData, config2 )
        //         .then((result) => {
        //             console.log(result.data);
        //             alert('Archivos cargados');
        //             closeModal()
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //             console.log(error.response)
        //         })*/
        
        //         await uploadFilesToServer(formData).then((result) => {
        //           console.log(result.data);
        //           //alert('Archivos cargados');
        //           setIsLoading(false)
        //           closeModal()
        //           handleRefresh()
        //           setMessage({ open: true, msg: result.data.msg, key: new Date(), type:"success" })
        //       }).catch((error) => {
        //           console.log(error);
        //           console.log(error.response)
        //           setMessage({ open: true, msg: error.response.data.msg, key: new Date(), type:"error" })
        //       })
        //         //console.log(response)
        
        
        // }
        try {
          if(  fileList.length !== 0){
            // API
            var data = {
              lat: props.chooseLoc[0],
              long: props.chooseLoc[1],
              transportmethods: transport,
              feeling: radioValue,
              features: selections,
              description: description,
              upfiles: fileList.map(file => file.content),
            }
  
            
            
            console.log(data);
            const response = await uploadFilesToServer(data)
            console.log(response)
            //closeModal()
            //handleRefresh()
            //setMessage({ open: true, msg: response.data.msg, key: new Date(), type:"success" })
          }
        } catch (error) {
          //setMessage({ open: true, msg: error.data.msg, key: new Date(), type:"error" })
          console.log(error)
        }
        
      }

    React.useEffect(() => {
        // Update the document title using the browser API
        if(activeStep === 0 && props.surveyDisplay === 'flex'){
          //console.log("Entra")
          props.setMarkerFlag(true)
          setTransport([])
        }else{
          props.setMarkerFlag(false)
        }
        
        
        if(activeStep === 2){
          setSelections([])
          setIsPositive(undefined)
        }
        if(activeStep === 3){
          setSelections([])
          
        }

        if(activeStep === 7 ){
            console.log('termino')
            uploadFiles()
            const payload = {
              lat: props.chooseLoc[0],
              long: props.chooseLoc[1],
              transportmethods: transport,
              feeling: radioValue,
              features: selections,
              description: description,
              img: 'img'
            } 
      
            //console.log(payload)
      
            setTransport([]);
            setRadioValue('');
            setSelections([]);
            setIsPositive(undefined);
            setDescription('')
            props.setChooseLoc([])
            setFileList([])
          }
    
        
      }, [activeStep]);

  return (
    <div style={{paddingTop:"5%"}}>
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start" >

            


            <Grid item xs={10} >
                <Box style={{height:'100%'}}>
                {
                    activeStep === 0 ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }} style={{fontSize: '20px'}}>Selecciona un punto en el mapa</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                
                                <Box sx={{ flex: '1 1 auto' }} />
                                

                                
                            </Box>
                            </React.Fragment>
                    ):
                    activeStep === 1 ? (
                        <div >
                            <Box >
                                
                                <FormGroup >
                                    <Grid container >
                                        <Grid item container  xs={6} direction="column">
                                        <Grid item>
                                            <FormControlLabel control={<Checkbox onChange={handleTransports} name="Auto compartido" color='check'/>} label="Auto compartido" />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel control={<Checkbox onChange={handleTransports} name="Auto particular" color='check'/>} label="Auto particular" />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel control={<Checkbox onChange={handleTransports} name="Bicicleta" color='check'/>} label="Bicicleta" />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel control={<Checkbox onChange={handleTransports} name="A pie" color='check'/>} label="A pie" />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel control={<Checkbox onChange={handleTransports} name="Motocicleta" color='check'/>} label="Motocicleta" />
                                        </Grid> 
                                        
                                        
                                        
                                        
                                        </Grid>
                                        
                                        <Grid item container xs={6} direction="column">
                                            <Grid item>
                                                <FormControlLabel control={<Checkbox onChange={handleTransports} name="Taxi" color='check'/>} label="Taxi" />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel control={<Checkbox onChange={handleTransports} name="Taxi App" color='check'/>} label="Taxi App" />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel control={<Checkbox onChange={handleTransports} name="Transporte público" color='check'/>} label="Transporte público" />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel control={<Checkbox onChange={handleTransports} name="Otro" color='check'/> } label="Otro" />
                                            </Grid>
                                        
                                        
                                        
                                        
                                        </Grid>
                                    
                                    </Grid>
                                
                                
                                </FormGroup>
                            </Box>
                        </div>
                    ):
                    activeStep === 2 ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }} style={{fontSize: '20px'}}>¿Cómo te sientes en este lugar?</Typography>
                            <FormControl>
                                {/*<FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>*/}
                                <RadioGroup
                                column
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={radioValue}
                                onChange={handleRadio}
                                
                                >
                                <FormControlLabel value="No me gusta" control={<Radio color='check' />} label={
                                    <InnerLabel text="No me gusta" emoji="👎"></InnerLabel>
                                } labelPlacement="rigth"
                                />
                                <FormControlLabel value="Me siento en peligro" control={<Radio color='check' />} label={
                                    <InnerLabel text="Me siento en peligro" emoji="😰"></InnerLabel>
                                } labelPlacement="rigth"/>
                                <FormControlLabel value="Me da tristeza" control={<Radio color='check' />} label={
                                    <InnerLabel text="Me da tristeza" emoji="😢" ></InnerLabel>
                                } labelPlacement="rigth"/>
                                <FormControlLabel value="Me gusta" control={<Radio color='check' />} label={
                                    <InnerLabel text="Me gusta" emoji="🤙"></InnerLabel>
                                } labelPlacement="rigth"/>
                                <FormControlLabel value="Me siento segura/o" control={<Radio color='check' />} label={
                                    <InnerLabel text="Me siento segura/o" emoji="🤗"></InnerLabel>
                                } labelPlacement="rigth"/>
                                <FormControlLabel value="Me encanta" control={<Radio color='check' />} label={
                                    <InnerLabel text="Me encanta" emoji="🤩"></InnerLabel>
                                }labelPlacement="rigth"/>
                                
                                
                                </RadioGroup>
                            </FormControl>
                        </React.Fragment>
                    ):activeStep === 3 ? (
                        <Box sx={{ maxHeight:"40vh", overflowY:'auto', flexGrow: 1}} >

          
                            <Grid container direction="row" >
                                {isPositive === false ? (
                                
                                <Grid item>
                                    
                                    <NegativeQuestion selections={selections} setSelections={setSelections} style={{maxHeight:"100%"}}></NegativeQuestion>
                                </Grid>
                                
                                
                                
                                ) :
                                isPositive === true ? (
                                
                                <>
                                <Grid item>
                                    <PositiveQuestion selections={selections} setSelections={setSelections} ></PositiveQuestion>
                                </Grid>
                                
                                </>
                                ):
                                (
                                <></>
                                )
                                }
                                

                            </Grid>
                        </Box>
                    ):
                    activeStep === 4 ? (
                        <Box sx={{ maxHeight:"50vh", overflowY:'auto', flexGrow: 1}} >
                          
                          <Grid container>
                          <ListComponent selections={selections} setSelections={setSelections}></ListComponent>
                          </Grid>
                        </Box>
                    ):
                    activeStep === 5 ? (
                        <React.Fragment>
                          
                          
                          <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="¿Tienes algo más que decir sobre este lugar? (opcional)"
                            value={description}
                            onChange={ev => setDescription(ev.target.value)}
                            style={{ width: '32vw', height:'30vh' }}
                          />
                        </React.Fragment>
                    ):
                    activeStep === 6 ? (
                        <React.Fragment>
                          
                          
                          { fileList.length === 0 ?
                            (
                              <DropBox onDrop={onDrop} />
                            ) :
                
                            <List>
                              {/* MAPPING THROUGH THE fileList variable to get file's path and render an icon based on its extension */}
                              {fileList.map((d, idx) => (
                                <ListItem
                                  divider
                                  key={idx}
                                  secondaryAction={
                                    <IconButton
                                      edge="end"
                                      onClick={() => deleteFileFromList(d.path)}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  }
                                >
                                  
                                  <ListItemText
                                    primaryTypographyProps={{
                                      overflow: 'hidden',
                                      lineHeight: '18px',
                                      fontSize: 15,
                                    }}
                                    primary={d.path}
                                  />
                                </ListItem>
                              ))}
                            </List>
                
                          }
                        </React.Fragment>
                    ):
                    activeStep === 7 ? (
                        <Typography sx={{ mt: 2, mb: 1 }} style={{fontSize: '20px'}}>¡Gracias! Tu participación es importante</Typography>
                    ):
                    (
                        <></>
                    )
                }
                
            </Box>
            </Grid>


            <Grid item xs={2}>
                <div className='buttonsdiv'>
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <Button variant="contained" style={{backgroundColor: '#FFFFFF', color: '#000000', borderRadius: 15,}} onClick={backWardStep} 
                            disabled={activeStep === 0 ? true : false }>
                                Atras
                            </Button>
                        </Grid>
                        <Grid item xs={6} >
                            {
                                activeStep !== 7 ? (
                                    <Button variant="contained" style={{backgroundColor: '#FFFFFF', color: '#000000', borderRadius: 15,}} onClick={forwardStep}>Siguiente</Button>
                                )
                                : (
                                    <Button variant="contained" style={{backgroundColor: '#FFFFFF', color: '#000000', borderRadius: 15,}} onClick={handleFinish}>Finalizar</Button>
                                )
                            }
                            
                        </Grid>
                    </Grid>
                    
                    
                </div>
            </Grid>
        </Grid>
        
        
        
        


        
        
    </div>
  )
}
