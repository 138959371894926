import React, { useState, useEffect } from 'react'
import { createTheme, useMediaQuery } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import { useTranslation } from 'react-i18next';

import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';



// import Typography from '@mui/material/Typography';

/*** Logo IMPORT */
import img from '../assets/img2.jpg'
// import top from '../assets/signup.PNG'
import top from '../assets/fotografiadron2.png'
import Swal from 'sweetalert2'


//Data
import dataUsers from "../data.json"

// API
import { login, getSchools, signup, getMapeos, download } from '../api/userAPI';

// Redux
import { deleteInfo } from '../redux/useReducer';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const CssTextFieldP = styled(OutlinedInput)({
  // '& label': {
  //   color: 'transparent'
  // },
  '& .css-1wc848c-MuiFormHelperText-root': {
    color: 'red'
  },
  '& label.MuiOuInputLabel-shrink': {
    color: 'transparent',
  },
  
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});
const CssTextField = styled(TextField)({
  // '& label': {
  //   color: 'transparent'
  // },
  '& .css-1wc848c-MuiFormHelperText-root': {
    color: 'red',
    paddingLeft: '2%',
    fontSize: '14px'
  },
  '& .Mui-error': {
    borderRadius: '100px',
    color: 'red'
  },
  '& label.MuiInputLabel-shrink': {
    color: 'transparent',
  },
  
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});
const CssSelect = styled(Select)({
   '& .MuiSelect-select': {
    '& select': {
      borderColor: 'transparent',
    },
    '&:hover select': {
      borderColor: 'transparent',
    },
    '&.Mui-focused select': {
      borderColor: 'transparent',
    },
   },
   
})

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};


const items = [
  
  {
    id: 1,
    title: 'Descargar base de datos',
    url: "/download",
    description: "Da click en navegar para descargar la base de datos de mi mapeo"
  },
  {
    id: 2,
    title: 'Operaciones a los usuarios',
    url: "/usuarios",
    description: "Da click en navegar para operar usuarios"

  },
  {
    id: 3,
    title: 'Ver mis puntos',
    url: "/mis-lugares",
    description: "Da click en navegar para ver tus puntos"

  },
  {
    id: 4,
    title: 'Mapear',
    url: "/overlay",
    description: "Da click en navegar para mapear"

  },
  // {
  //   id: 2,
  //   title: 'Operaciones a los usuarios',
  //   url: "/usuarios",
  //   description: "Da click en navegar para operar usuarios de mi mapeo"

  // },
  // {
  //   id: 3,
  //   title: 'Crear un nuevo sub admin',
  //   url: "/nuevo-subadmin",
  //   description: "Da click en navegar para crear un nuevo usuario subadmin"

  // },
]

export default function SubAdmin() {

  const { t, i18n } = useTranslation();


  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate();
    const dispatch = useDispatch();

  //States
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [matricula, setMatricula] = useState('');
  const [genero, setGenero] = useState('');
  const [escuela, setEscuela] = useState('');
  const [mes_anio_nacimiento, setFechaN] = useState('');

  const [mapeos, setMapeos] = useState([])
  const [mapeo, setMapeo] = useState('')

  const [codigo, setCodigo] = useState('')

  const [escuelas, setEscuelas] = useState([])
  const [open, setOpen] = useState(false);
  const [globalError, setGlobalError] = useState('');
  const [showPassword, setShowPassword] = useState(false);


  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorMatricula, setErrorMatricula] = useState('');
  const [errorGenero, setErrorGenero] = useState('');
  const [errorFechaN, setErrorFechaN] = useState('');
  const [errorEscuela, setErrorEscuela] = useState('');

  const [loading, setLoading] = useState(false)

  
  const styles ={
    
    imglogocontainer:{
      width: '100vw',
      height: '35vh'
    },
    imglogo:{
      width: '100%',
      height: '100%'
    },
    formContainer: {
      backgroundColor: '#eeca45',
      padding: '3%',
      color: 'white',
      marginTop: '-20px'
    },
    field: {
      // width: '200%',
      color: 'white',
      backgroundColor: 'white',
      borderRadius: '100px',
    },
    fieldP: {
      // width: '160%',
      // color: 'white',
      backgroundColor: 'white',
      borderRadius: '100px',
      border: 'none',
      borderColor: 'transparent'
      
    }

    
  }

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const downloadExcel = (data, fileName) => {
    
    
      const wb = XLSX.utils.book_new();
  
      const ws1 = XLSX.utils.json_to_sheet(data);
      
      XLSX.utils.book_append_sheet(wb, ws1, 'Datos del mapeo');
  
  
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
      };
      saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    
    };

    //Handler for submit
    const submitHandler = async () => {
      setLoading(true)
      let error = false;
    
    //Validation
    // if(!validateEmail(email)){
    //   setErrorEmail('Email incorrecto')
    //   setGlobalError('Revisa el formulario, aún hay campos incorrectos')
    //   error = true;
    // }
    // if(password.length < 8){
    //   setErrorPassword('La contraseña es de al menos 8 caracteres')
    //   setGlobalError('Revisa el formulario, aún hay campos incorrectos')
    //   error = true;
    // }
    // if(matricula.length < 8) {
    //   setErrorMatricula('La matricula es de al menos 8 caracteres')
    //   setGlobalError('Revisa el formulario, aún hay campos incorrectos')
    //   error = true;
      
    // }
    // if(genero === '') {
    //   setErrorGenero('Selecciona tu género')
    //   setGlobalError('Revisa el formulario, aún hay campos incorrectos')
    //   error = true;
      
    // }
    // if(escuela === "") {
    //   setErrorEscuela('Selecciona tu escuela')
    //   setGlobalError('Revisa el formulario, aún hay campos incorrectos')
    //   error = true;
      
    // }
    // if(mes_anio_nacimiento === "") {
    //   setErrorFechaN('Selecciona tu fecha de nacimiento')
    //   setGlobalError('Revisa el formulario, aún hay campos incorrectos')
    //   error = true;
    // }


    if(codigo !== '00000000') {
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
    }
    const map = {
        idMapeo: mapeo
    }
    
    if(error) {
      setOpen(true)
    } else {
      const result = await download(map)
      if(result.status == 400) {
        setOpen(true)
        setGlobalError(result.data.msg)
      } else {
        console.log(result)
        downloadExcel(result.data.data, ` Mapeo - ${mapeo}` )
        navigate('/login')
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Base de datos descargada!',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }

    setLoading(false)

    }

    // Handle for close
    const handleClose = () => {

        setOpen(false)

    };

    // Handle Email
    const handleEmail = (e) => {

        setEmail(e.target.value)
        setErrorEmail('')

    }
    //Handle Password
    const handlePassword = (e) => {

        setPassword((e.target.value))
        setErrorPassword('')

    }
    // Handle Matricula
    const handleMatricula = (e) => {

        setMatricula(e.target.value)
        setErrorMatricula('')

    }
    //Handle Escuela
    const handleEscuela = (e) => {

        setEscuela((e.target.value))
        setErrorEscuela('')

    }
    //Handle Mapeo
    const handleMapeo = (e) => {

      setMapeo((e.target.value))

  }
    // Handle FechaN
    const handleFechaN = (e) => {

        setFechaN(e.target.value)
        setErrorFechaN('')

    }
    //Handle Genero
    const handleGenero = (e) => {

        setGenero((e.target.value))
        setErrorGenero('')

    }

  
  const fetchSchools = async () => {

    const result = await getSchools()
    console.log(result)
    setEscuelas(result.data.data)

  }

  const fetchMapeos = async () => {

    const result = await getMapeos()
    console.log(result)
    setMapeos(result.data.data || [])

  }

  useEffect(() => {
    //fetchMapeos()
  }, [])

 


  return (
    <>
      {/* <div style={styles.imglogocontainer}>
      <img src={top} style={styles.imglogo}/>
    </div> */}
    <Grid container justifyContent="center"
    alignItems="center">


      <Typography variant="h1" gutterBottom>
        Operaciones de Administrador 
      </Typography>

      <Grid item container spacing={3} >

      {
        items.map(item => (
          <Grid key={item.id } item md={4} >
             <Card >
              <CardContent>
                <Typography variant="h5" component="div">
                {item.title}                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => navigate(item.url)}>Navegar</Button>
              </CardActions>
            </Card>
          </Grid>
        ))
      }
      </Grid>

    </Grid>
    </>
    
  )
}