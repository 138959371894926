import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// API
import { userInfo } from '../api/userAPI'


// Initial State
const initialState = {
    id: null,
    code: '',
    matricula: '',
    status: null,
    lng: ''
}


// Actions
export const getUserInfo = createAsyncThunk(
  'user/getUserInfo',
  async (dispatch, getState, ) => {
    const response = await userInfo();
    console.log(1, response)
    return {
      id: response.id,
      matricula: response.matricula
    }
  }
)


// Reducer
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    deleteInfo: (state) => {
      state.id = null
      state.matricula = ''
      state.status = null
      state.lng = ''
    }
  },
  extraReducers: {
    [getUserInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getUserInfo.fulfilled]: (state, action) => {
      state.id = action.payload.id
      state.matricula = action.payload.matricula
      state.status = 'success'
    },
    [getUserInfo.rejected]: (state, action) => {
      state.status = 'failed'
    },
  }
})

export default userSlice.reducer
export const { deleteInfo } = userSlice.actions