import React from 'react'
import { useTranslation } from 'react-i18next';

//MUI
import Box from "@mui/material/Box";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

//Components
import InnerLabel from '../InnerLabel';


export default function PlaceFeeling(props) {
    const { radioValue, setRadioValue, setSelections } = props;
    const { t, i18n } = useTranslation();

    const handleRadio = (event) =>{
        setRadioValue(event.target.value);
        setSelections([])
        console.log(radioValue)
       }

  return (
    <Box>
        <FormControl>
                                {/*<FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>*/}
                                <RadioGroup
                                
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={radioValue}
                                onChange={handleRadio}
                                
                                >
                                <FormControlLabel value="No me gusta" control={<Radio color='check' />} label={
                                    <InnerLabel text={t('enc.p1')} emoji="👎"></InnerLabel>
                                } 
                                />
                                <FormControlLabel value="Me siento en peligro" control={<Radio color='check' />} label={
                                    <InnerLabel text={t('enc.p2')}  emoji="😰"></InnerLabel>
                                } />
                                <FormControlLabel value="Me da tristeza" control={<Radio color='check' />} label={
                                    <InnerLabel text={t('enc.p3')}  emoji="😢" ></InnerLabel>
                                } />
                                <FormControlLabel value="Me gusta" control={<Radio color='check' />} label={
                                    <InnerLabel text={t('enc.p4')}  emoji="🤙"></InnerLabel>
                                } />
                                <FormControlLabel value="Me siento segura/o" control={<Radio color='check' />} label={
                                    <InnerLabel text={t('enc.p5')}  emoji="🤗"></InnerLabel>
                                } />
                                <FormControlLabel value="Me encanta" control={<Radio color='check' />} label={
                                    <InnerLabel text={t('enc.p6')}  emoji="🤩"></InnerLabel>
                                } />
                                
                                
                                </RadioGroup>
                            </FormControl>
    </Box>
  )
}
