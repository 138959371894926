import React from 'react'
import { useTranslation } from 'react-i18next';

//MUI
import { Grid, Typography } from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import FormGroup from '@mui/material/FormGroup';
import { blueGrey } from '@mui/material/colors';

export default function TransportsRadios(props) {
    const [value, setValue] = React.useState('female');
    const { handleTransports, transport } = props

    const { t, i18n } = useTranslation();


  const radioColor = {
    color: blueGrey[900],
    '&.Mui-checked': {
      color: blueGrey[900],
    }
  }

  return (
    <Box >
        <FormControl >
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="masculino"
            name="radio-buttons-group"
            onChange={handleTransports}
            value={transport}
            
          >
                    
                    <FormControlLabel value="Auto compartido" control={<Radio color='check' />} 
                    label={<Typography style={{fontSize:"0.9em", color:"black"}}>{t('enc.t1')}</Typography>}  />

                     <FormControlLabel value="Auto particular" control={<Radio color='check'/>}
                      label={<Typography style={{fontSize:"0.9em", color:"black"}}>{t('enc.t2')}</Typography>} />

                    <FormControlLabel value="Bicicleta" control={<Radio color='check'/>} 
                    label={<Typography style={{fontSize:"0.9em", color:"black"}}>{t('enc.t3')}</Typography>} />

                    <FormControlLabel value="A pie" control={<Radio color='check'/>} 
                    label={<Typography style={{fontSize:"0.9em", color:"black"}}>{t('enc.t4')}</Typography>} />

                    <FormControlLabel value="Moto" control={<Radio color='check'/>} 
                    label={<Typography style={{fontSize:"0.9em", color:"black"}}>{t('enc.t5')}</Typography>} />

                    <FormControlLabel value="Taxi" control={<Radio color='check'/>} 
                    label={<Typography style={{fontSize:"0.9em", color:"black"}}>{t('enc.t6')}</Typography>} />

                    <FormControlLabel value="Taxi App" control={<Radio  color='check'/>} 
                    label={<Typography style={{fontSize:"0.9em", color:"black"}}>{t('enc.t7')}</Typography>} />

                    <FormControlLabel value="Transporte público" control={<Radio color='check'/>} 
                    label={<Typography style={{fontSize:"0.9em", color:"black"}}>{t('enc.t8')}</Typography>} />
                 
                 {/*
                 <Grid container >
                                        <Grid item container  xs={6} direction="column">
                                        <Grid item>
                                            <FormControlLabel control={<Checkbox onChange={handleTransports} name="Auto compartido" color='check'/>} label="Auto compartido" />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel control={<Checkbox onChange={handleTransports} name="Auto particular" color='check'/>} label="Auto particular" />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel control={<Checkbox onChange={handleTransports} name="Bicicleta" color='check'/>} label="Bicicleta" />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel control={<Checkbox onChange={handleTransports} name="A pie" color='check'/>} label="A pie" />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel control={<Checkbox onChange={handleTransports} name="Motocicleta" color='check'/>} label="Motocicleta" />
                                        </Grid> 
                                        
                                        
                                        
                                        
                                        </Grid>
                                        
                                        <Grid item container xs={6} direction="column">
                                            <Grid item>
                                                <FormControlLabel control={<Checkbox onChange={handleTransports} name="Taxi" color='check'/>} label="Taxi" />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel control={<Checkbox onChange={handleTransports} name="Taxi App" color='check'/>} label="Taxi App" />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel control={<Checkbox onChange={handleTransports} name="Transporte público" color='check'/>} label="Transporte público" />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel control={<Checkbox onChange={handleTransports} name="Otro" color='check'/> } label="Otro" />
                                            </Grid>
                                        
                                        
                                        
                                        
                                        </Grid>
                                    
                                    </Grid>
        
                */}
        </RadioGroup>
        </FormControl>
    </Box>
  )
}

