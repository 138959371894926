import L from "leaflet";
import iconito from "../assets/venue_location_icon_negative.svg"

export const IconLocationRed = L.icon({
    //iconUrl: require("../assets/venue_location_icon.svg"),
    iconUrl: iconito,
    iconRetinaUrl: iconito,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [50,50],
    className: "leaflet-venue-icon"

}) 