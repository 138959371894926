
import dataUsers from '../data.json'
import store from '../redux/store'
import axios, { instanceI as axiosImages } from './axiosConfig';

  


export const userInfo = async() => {
    return await axios.get(`/user/getUserInfo`)
  }
export const login = async(data) => {
    //return dataUsers.users.some(user => user.user === dataUser.user && user.password === dataUser.password)
    return await axios.post(`/user/login`, data)
}
export const savePositon = async(data) => {
  return await axios.post(`/user/postposition`, data)
}
export const logout = async () => {
  return await axios.get('/user/logoff')
}
export const signup = async(data) => {

  return await axios.post('/user/signup', data)
}
export const signupAdmin = async(data) => {

  return await axios.post('/user/signupadmin', data)
}
export const signupSubAdmin = async(data) => {

  return await axios.post('/user/signupsubadmin', data)
}
export const uploadFilesToServer = async (data) => {

  const formData = new FormData();
  formData.append('lat', data.lat)
  formData.append('long', data.long)
  formData.append('transportmethods', data.transportmethods)
  formData.append('feeling', data.feeling)
  formData.append('features', data.features)
  formData.append('description', data.description)
  
  data.upfiles.map( (file) =>{
    formData.append('upl', file);
  });
  return await axiosImages.post(`/user/uploadLocation`, formData)

}

export const newLocation = async (data) =>{
  return await axios.post('/user/newlocation', data)
}

export const getAllPlaces = async(code) => {

  return await axios.post('/user/getplaces', code)
}
export const getAllPlacesIn = async() => {

  return await axios.get('/user/getplacesin')
}
export const getMyPlaces = async() => {
  return await axios.get('/user/getmyplaces')
}
export const getImage = async(data) => {
  
  return await axios.post('/user/getfile', data)
}
export const getSchools = async() => {
  
  return await axios.get('/user/getschools')
} 
export const getMapeos = async() => {
  
  return await axios.get('/user/getmapeos')
} 
export const getUsuarios = async() => {
  
  return await axios.get('/user/getusuarios')
} 
export const download = async(mapeo) => {
  return await axios.post('/user/download', mapeo)
}
export const download2 = async() => {
  return await axios.get('/user/download2')
}
export const passrecover = async(data) => {
  
  return await axios.post('/user/passrecover', data)
} 

export const newMapping = async(data) => {

  return await axios.post('/user/newmapping', data)
}
export const deleteMapeo = async(data) => {

  return await axios.post('/user/deletemapeo', data)
}
export const deleteUsuario = async(data) => {

  return await axios.post('/user/deleteusuario', data)
}
export const updateMapeo = async(data) => {

  return await axios.post('/user/updatemapeo', data)
}
export const updateUsuario = async(data) => {

  return await axios.post('/user/updateusuario', data)
  
}