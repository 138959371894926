import React from 'react'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next';

// import { MapContainer } from 'react-leaflet/MapContainer'
// import { TileLayer } from 'react-leaflet/TileLayer'
import { useMap } from 'react-leaflet/hooks'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, ImageOverlay, ZoomControl } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import './overlay.css'

//Components
import { IconLocation } from '../components/IconLocation'
import { PointLocation } from '../components/PointLocation'
import Markers from '../components/Markers'
import places from '../assets/lugares.json'
import { MyPlanet } from '../components/MyPlanet'
import TestSurvey from './TestSurvey'
import Top from '../components/Top'
import Bar from '../components/Bar'
import Buttons from '../components/ButtonsPrivate'
import ButtonsActions from '../components/ButtonsActionspPrivate'
import TestDesignSurvey from './TestDesignSurvey'
import Button from '@mui/material/Button';



//MUI
import Grid from '@mui/material/Grid';
import Encuesta from '../components/Encuesta'

//Images
import backgroundSurvey from '../assets/encuesta/cajasencuestas.png'


import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import mundo from '../assets/mundo.png'

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function TestOverlay() {


  const localData = JSON.parse(localStorage.getItem('data')) || {lng:"es",country:"es-2"};
  const localData2 = JSON.parse(localStorage.getItem('coords')) || {lat:0, lon:0 };



  const [surveyDisplay, setSurveyDisplay] = React.useState('flex')
  const [markerFlag, setMarkerFlag] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0);

  const [chooseLoc, setChooseLoc] = React.useState([]);

  const [currentPoint, setCurrentPoint] = useState([])

  const [points, setPoints] = useState([
  ])
  const [lat, setLat] = useState(localData2.lat),
        [lon, setLon] = useState(localData2.lon)

        const [code, setCode] = useState('')

  
  const [selectedPosition, setSelectedPosition] = useState([0,0]);

  const [value, setValue] = useState(2)
  const [country, setCountry] = useState(localData.country);


  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCode('')
    setOpen(false);
  };
  const handleSubmit = (e) => {
    console.log(code)
    e.preventDefault()
    alert(`Code: ${code}`)
    handleClose()
  }

  const handleCountry = (event) => {
    setCountry(event.target.value);
  };


  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const markerRef = useRef(null)
  const eventHandlers = useMemo(
      () => ({
      dragend() {
          const marker = markerRef.current
          if (marker != null) {

              const objectCoords = marker.getLatLng()
              setLat(objectCoords.lat)
              setLon(objectCoords.lng)
          }
      },
      }),
      [],
  )

  useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            function(position) {
                 console.log(position.coords)
                // const converstion = Maps.newGeocoder().reverseGeocode(position.coords.latitude, position.coords.longitude)
                // // console.log(converstion)
                setLon(position.coords.longitude)
                setLat(position.coords.latitude)
                
            }, 
            function(error) {
                // console.log(error)
            }, {
                enableHighAccuracy: true
            })
        
}, [])

useEffect(() => {
  if(selectedPosition[0] !== 0 && selectedPosition[1] !== 0) 
  console.log(markerFlag)

  if(markerFlag === true){
    //setPoints(points.slice(0, -1));
    //setPoints([...points, selectedPosition])
    setChooseLoc(selectedPosition)
  }  
  

}, [selectedPosition])


  //Functions

  //Add a new point
  const Markers = () => {

    const map = useMapEvents({
        click(e) { 
          
                                    
            setSelectedPosition([
                e.latlng.lat,
                e.latlng.lng
            ]);
          
            // console.log(e.latlng)
                         
        },            
    })
  
    
}

  return (
    
    <Grid container spacing={2} justify="flex-end">
        <Box sx={{ minWidth: 120 }}>
         <Dialog open={open} onClose={handleClose} fullWidth={false}
             maxWidth={'xs'} style={{zIndex: '200000'}}>
        <DialogTitle>{t("landing.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {t("landing.dialog.body")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="code"
            label={t("landing.dialog.codigo")}
            type="tesxt"
            fullWidth
            variant="standard"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("landing.dialog.cancelar")}</Button>
          <Button onClick={handleSubmit}>{t("landing.dialog.empezar")}</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ minWidth: 150 }} style={{ backgroundColor: 'transparent',
            position: 'absolute', right: '20px', zIndex: '10001', margin: 20, borderRadius: '10px'}}>
            <FormControl  style={{ backgroundColor: 'transparent', color: 'white', borderRadius: '10px'}} fullWidth>
              <InputLabel id="demo-simple-select-label">{t("landing.languages.idiomas")}</InputLabel>
              <Select

                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={country}
                label="Age"
                onChange={handleCountry}
                style={{border: 'transparent', backgroundColor: '#9f62a7', color: 'white'}}
              >
                                
                <MenuItem onClick={() => changeLanguage("es")} value={'es-2'}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={4}>
                <img src={mundo} width={30} height={30}></img>                
                </Grid>
                <Grid item xs={7}>
                  {t("landing.languages.español")}               
                </Grid>
                 
            </Grid>
                 
                  
                </MenuItem>
                <MenuItem onClick={() => changeLanguage("en")} value={'en-1'}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={4}>
                <img src={mundo} width={30} height={30}></img>
                </Grid>
                <Grid item xs={7}>
                {t("landing.languages.ingles")}
                </Grid>
                 
            </Grid>
                </MenuItem>
              </Select>
            </FormControl>
           </Box>
         
    </Box>
        <div className="grid">
        
        <div className='map-box'>
        <Top />
        
           <Bar text={value === 1 ? t('landing.textOptions.text1') : value === 2 ? t('landing.textOptions.text2') : t('landing.textOptions.text3')}value={value} />
         <Buttons value={value} setValue={setValue}/>
         <ButtonsActions />
            <MapContainer 
              zoomControl={false}
                center={lat && lon ? [lat, lon] : [19.246034, -103.718372]} // 
                zoom={13} 
                scrollWheelZoom={true}
                // onClick={addPoint}
            >
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <ZoomControl position={'bottomleft'} />
                {/* <Markers places={places}></Markers> */}
                {/* <Marker
                draggable={true}
                eventHandlers={eventHandlers}
                ref={markerRef}
                position={[lat, lon]} 
                icon={IconLocation}
                >
                <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
                </Marker> */}
                <Markers />
                {
                points.map((point, index) => (
                    <Marker 
                    key={`marker-${index}`} 
                    position={point}
                    draggable={false}
                    // eventHandlers={eventHandlers}
                    // ref={markerRef}
                // position={[lat, lon]} 
                    icon={PointLocation}
                >
                    <Popup>
                    <span>Tu estás aquí. <br/> Desplazate a cualquier lugar.</span>
                    </Popup>
                </Marker>
                ))
                }
                {
                  chooseLoc.length === 2 && (
                    <Marker 
                      key='choosedMarker' 
                      position={chooseLoc}
                      draggable={true}
                      // eventHandlers={eventHandlers}
                      // ref={markerRef}
                  // position={[lat, lon]} 
                      icon={IconLocation}
                    >
                        <Popup>
                        <span>Tu estás aquí. <br/> Desplazate a cualquier lugar.</span>
                        </Popup>
                    </Marker>
                  )
                }
                
            </MapContainer>
          </div>
          

          {/*
          <div className='data-box'>
            <div style={{backgroundColor:"red", paddingTop:"10px", paddingLeft:"50px", zIndex:'100000 !important'}}>
                asdjasdkjasjdlkaskdjaksjdakld
                aksdjkasdlajsdjlk
                <MyPlanet></MyPlanet>
            </div>
          </div>
            */}
          {/* <div className='data-box-menu' >
            <MyPlanet surveyDisplay={surveyDisplay} setSurveyDisplay={setSurveyDisplay} setMarkerFlag={setMarkerFlag} setActiveStep={setActiveStep}></MyPlanet>
            
          </div> */}
          {/*
          <div className='data-box' style={{display: surveyDisplay, backgroundImage: `url(${backgroundSurvey})` }} >
            
            <Encuesta setMarkerFlag={setMarkerFlag} surveyDisplay={surveyDisplay}  activeStep={activeStep} setActiveStep={setActiveStep} chooseLoc={chooseLoc} setChooseLoc={setChooseLoc}></Encuesta>
            
          </div>
          */}

          {/*
          <TestSurvey setMarkerFlag={setMarkerFlag} surveyDisplay={surveyDisplay} setSurveyDisplay={setSurveyDisplay}  activeStep={activeStep} setActiveStep={setActiveStep} chooseLoc={chooseLoc} setChooseLoc={setChooseLoc}></TestSurvey>
        */}
        <div className='data-box-new' style={{display: surveyDisplay }} >
        <TestDesignSurvey setMarkerFlag={setMarkerFlag} surveyDisplay={surveyDisplay} setSurveyDisplay={setSurveyDisplay}  activeStep={activeStep} setActiveStep={setActiveStep} chooseLoc={chooseLoc} setChooseLoc={setChooseLoc}></TestDesignSurvey>
        </div>  
        
      
    </div>
  </Grid>

  )
}
