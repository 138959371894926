import React, { forwardRef, useRef, useEffect, useState, useCallback } from "react"
import { useDropzone } from 'react-dropzone';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

//Icon
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


//Border color function for container
/*const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};*/

function DropBox({ onDrop, image=false }) {

  const { t, i18n } = useTranslation();

  //Destructured dropbox props and accepted files
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    multiple: image? false: true,
    accept: image ? 'image/*': 'image/*, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .csv, audio/*',
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  // Styles
  const styles = {
    containerStyle:{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign:"center",
      padding: "0.5%",
      borderWidth: "2px",
      borderRadius: "10px",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "black",
      fontWeight: "bold",
      fontSize: "1.4rem",
      outline: "none",
      transition: "border 0.24s ease-in-out",
      // borderColor: `${(props) => getColor(props)}`
    },
    dropBoxStyle:{
      backgroundColor:"green",
      textAlign: "center",   
    }
  }

  return (
    <>
      {/** Main section */}
      <section style={{alignItems:"center", textAlign:"center"}}>
        {/** Container for the input tag */}
        <Container
          style={styles.containerStyle}
          {...getRootProps()}
          // {...getRootProps({
          //   isdragaccept:isDragAccept,
          //   isFocused,
          //   isDragReject
          // })}
        >
          <input
            {...getInputProps()}
          />
          {image ? <p>{t('enc.arimg')}</p> :  <p>{t('enc.arfile')}</p> }
        </Container> {/** End Container */}

        {/** Button to open the file chooser */}
        <Button variant="contained" onClick={open} style={{marginTop:"5%"}}>
          <CloudUploadIcon style={{marginRight:"5px"}}></CloudUploadIcon>
          {image ?  t('enc.eimg') : t('enc.efile')}
        </Button>
      </section>
    </>
  );
}
export default DropBox;