import React, { useState, useEffect } from 'react'
import { createTheme, useMediaQuery } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
//MUI
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField'

import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button'
import Volver from '../components/Volver';


import MUIDataTable from "mui-datatables";







import Swal from 'sweetalert2'


import { login, getSchools, signup, getMapeos, download, getusuarios, deleteMapeo, updateMapeo } from '../api/userAPI';

// Redux
import { deleteInfo } from '../redux/useReducer';


const data = [
 ["Joe James", "Test Corp", "Yonkers", "NY"],
 ["John Walsh", "Test Corp", "Hartford", "CT"],
 ["Bob Herm", "Test Corp", "Tampa", "FL"],
 ["James Houston", "Test Corp", "Dallas", "TX"],
];




const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});



export default function Mapeos() {

  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  

  const [data, setData] = useState([]);
  const [mapeos, setMapeos] = useState([])


  const [isNew, setIsnew] = useState(true);

  const [nombre, setNombre] = useState('')
  const [siglas, setSiglas] = useState('')
  const [idToEdit, setIdToEdit] = useState(0)

  const [clienteId, setClienteId] = useState(0)

  const [codigo, setCodigo] = useState('');
  const [titulo, setTitulo] = useState('');
  const [dias, setDias] = useState(0);
  const [open, setOpen] = useState(false);


  const navigate = useNavigate();

 

  const options = {
    selectableRows: false,
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  const { t, i18n } = useTranslation();


  
  const styles ={
    
    imglogocontainer:{
      width: '100vw',
      height: '35vh'
    },
    imglogo:{
      width: '100%',
      height: '100%'
    },
    formContainer: {
      backgroundColor: '#eeca45',
      padding: '3%',
      color: 'white',
      marginTop: '-20px'
    },
    field: {
      // width: '200%',
      color: 'white',
      backgroundColor: 'white',
      borderRadius: '100px',
    },
    fieldP: {
      // width: '160%',
      // color: 'white',
      backgroundColor: 'white',
      borderRadius: '100px',
      border: 'none',
      borderColor: 'transparent'
      
    }

    
  }

  const clear = () => {
    setCodigo('')
    setTitulo('')
    setDias(0)
  }

  const handleClose = () => {
    setOpen(false);
    clear();
  };

  const handleClickOpen = () => {
    setOpen(true);

    
  };
  const handleCodigo = (e) => {

    setCodigo(e.target.value)

}

//Handle Password
const handleTitulo = (e) => {

    setTitulo((e.target.value))

}

const handleDias = (e) => {

    setDias((e.target.value))

}


  const fetchMapeos= async () => {

    const result = await getMapeos()
    console.log(result)
    const normalData = result.data.data;
    const dataDisplay = normalData.map(item => [item.idmapeo, item.titulo, item.codigo, item.dias]);

   setData(dataDisplay);
   setMapeos(normalData)

  }

  useEffect(() => {
    fetchMapeos()
  }, [])

  const updateDataMapeo = async () => {

    const data = {
      id: idToEdit,
      dias,
      titulo,
      codigo
    }

    try {
      const result = await updateMapeo(data)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Mapeo editado exitosamente!',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (error) {
      console.log(error)
    }
    navigate(0)

  }
  const editItem  = async(id) => {
    
    const {titulo, codigo, dias} = mapeos.find(mapeo => mapeo.idmapeo == id)

    setCodigo(codigo)
    setTitulo(titulo)
    setDias(dias)
    setIdToEdit(id)

    handleClickOpen()

    
  }
  const deleteMapeo1= async (id) => {
     
    const data = {
      id
  }

  try {
    const result = await deleteMapeo(data)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Mapeo eliminado exitosamente!',
      showConfirmButton: false,
      timer: 1500
    })
  } catch (error) {
    console.log(error)
  }
  navigate(0)


  }
  const chooseDelete = async(id) => {
  
    Swal.fire({
      title: '¿Estas seguro que quieres borrar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, seguro!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMapeo1(id)
        Swal.fire(
          'Eliminado!',
          '',
          'success'
        )
      }
    })
  }
    
 
  const columns = [
    "ID",
    "Título",
    "Código",
    "Vigencia",
    {
      name: "Editar",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
                                        onClick={() => editItem(tableMeta.rowData[0])}
                                    >
                                        <Edit />
                                    </IconButton>
          );
        }
      }
    },
    {
      name: "Eliminar",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
                                        onClick={() => chooseDelete(tableMeta.rowData[0])}
                                    >
                                        <Delete/>
                                    </IconButton>
          );
        }
      }
    },
    
  ];


  return (
    <>
      {/* <div style={styles.imglogocontainer}>
      <img src={top} style={styles.imglogo}/>
    </div> */}
    <Grid container justifyContent="center"
    alignItems="center">


      <Grid item md={12} container justifyContent="center"
    alignItems="center">
      <Typography variant="h1" gutterBottom>
        Mapeos
      </Typography>
      </Grid>

      <Grid item>
        <MUIDataTable
          title={"Usuarios"}
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>

    </Grid>

    <Dialog open={open} onClose={handleClose} 
        maxWidth={'md'}>
        <DialogTitle>{ 'Editar registro'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingresa todos los campos necesarios para editar 
          </DialogContentText>

          <Grid item container xs={12} spacing={2}>
            <Grid item container md={6} spacing={2}>
            <Grid item xs={10} md={8} >
          <TextField
          
          fullWidth
            required
            id="outlined-required"
            label="Código por asignar"
            onChange={handleCodigo}
            value={codigo}


          />
        </Grid>

        <Grid item xs={10} md={8}>
          <TextField
          fullWidth
            required
            id="outlined-password-input"
            label="Título"
            type="text"
            autoComplete="current-password"
            onChange={handleTitulo}
            value={titulo}
          />
        </Grid>
        <Grid item xs={10} md={8} >
          <TextField
         
          fullWidth
            required
            id="outlined-required"
            label="Días de vigencia"
            type='number'
            onChange={handleDias}
            value={dias}


          />
        </Grid>

            </Grid>
          </Grid>


      
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={updateDataMapeo}>{ 'Editar'}</Button>
        </DialogActions>
      </Dialog>
      <Volver />

    </>
    
  )
}