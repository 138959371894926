import React from 'react'
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';


export default function PositiveQuestion(props) {

    const { t, i18n } = useTranslation();

     //Options
     const [options, setOptions] = React.useState({
        'Velocidades adecuadas/seguras':false,
        'Banquetas en buen estado': false,
        'Cruces seguros': false,
        'Buen mantenimiento vial':false,
        'Me siento segura/o caminando':false,
        'Me siento segura/o en la bici':false,
        'Buena iluminación': false,
        'Muy concurrido': false,
        'Personas amigables': false,
        'Personas conocidas': false,
        'Comparto con mis amigas/os': false,
        'Comparto con mi familia': false,
        'Ejercito mi cuerpo y mente': false,
        'Paseo y juego con mis mascotas (pet friendly)':false,
        'Hay espacios para jóvenes':false,
        'Hay juegos':false,
        'Sin ruido/tranquilo':false,
        'Esta limpio/No hay basura':false,
        'Aire limpio/fresco':false,
        'Muchos árboles/sombra':false,
        'Huerta urbana':false
    });


    const handleOptions = (event) =>{

        if(event.target.checked === true && props.selections.length < 5){
            
            options[event.target.name] = true;
            props.setSelections([...props.selections,event.target.name])
            
        }else{
            options[event.target.name] = false;
            props.setSelections((state) => state.filter((item) => item !== event.target.name))
        }

        
        setOptions({...options})
    }

     //Styles
     const styles = {
        optionsBox: {
            paddingLeft: "15%"
        }
    }

    React.useEffect(()=>{
        if(props.selections.length >= 1){
            props.selections.map(
                (selopt) =>{
                    options[selopt] = true;
                    setOptions({...options})
                }
            );
        }

    },[]);
    
  return (
    <Grid container direction="column" style={{color:"black"}}>
        <Grid item>
            <Typography variant="subtitle1"> {t('enc.pp61')} </Typography>
            <Box style={styles.optionsBox}>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Velocidades adecuadas/seguras']} name='Velocidades adecuadas/seguras'/>} label={t('enc.g1')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Banquetas en buen estado']} name='Banquetas en buen estado'/>} label={t('enc.g2')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Cruces seguros']} name='Cruces seguros'/>} label={t('enc.g3')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Buen mantenimiento vial']} name='Buen mantenimiento vial'/>} label={t('enc.g4')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Me siento segura/o caminando']} name='Me siento segura/o caminando'/>} label={t('enc.g5')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Me siento segura/o en la bici']} name='Me siento segura/o en la bici'/>} label={t('enc.g6')} />
                        </FormGroup>
            </Box>
            <Divider variant="middle" />
        </Grid>
        

        <Grid item>
            <Typography variant="subtitle1"> {t('enc.pp62')} </Typography>
            <Box style={styles.optionsBox}>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Buena iluminación']} name='Buena iluminación'/>} label={t('enc.g7')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Muy concurrido']} name='Muy concurrido'/>} label={t('enc.g8')}/>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['No se registran robos']} name='No se registran robos'/>} label={t('enc.g9')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Personas amigables']} name='Personas amigables'/>} label={t('enc.g10')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Personas conocidas']} name='Personas conocidas'/>} label={t('enc.g11')} />
                            
                        </FormGroup>
            </Box>
            <Divider variant="middle" />
        </Grid>

        <Grid item>
            <Typography variant="subtitle1">  {t('enc.pp63')}</Typography>
            <Box style={styles.optionsBox}>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Comparto con mis amigas/os']} name='Comparto con mis amigas/os'/>} label={t('enc.g12')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Comparto con mi familia']} name='Comparto con mi familia'/>} label={t('enc.g13')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Ejercito mi cuerpo y mente']} name='Ejercito mi cuerpo y mente'/>} label={t('enc.g14')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Paseo y juego con mis mascotas (pet friendly)']} name='Paseo y juego con mis mascotas (pet friendly)'/>} label={t('enc.g15')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Hay espacios para jóvenes']} name='Hay espacios para jóvenes'/>} label={t('enc.g16')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Hay juegos']} name='Hay juegos'/>} label={t('enc.g17')} />
                            
                        </FormGroup>
            </Box>
            <Divider variant="middle" />
        </Grid>

        <Grid item>
            <Typography variant="subtitle1"> {t('enc.pp64')} </Typography>
            <Box style={styles.optionsBox}>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Sin ruido/tranquilo']} name='Sin ruido/tranquilo'/>} label={t('enc.g18')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Esta limpio/No hay basura']} name='Esta limpio/No hay basura'/>} label={t('enc.g19')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Aire limpio/fresco']} name='Aire limpio/fresco'/>} label={t('enc.g20')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Muchos árboles/sombra']} name='Muchos árboles/sombra'/>} label={t('enc.g21')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Huerta urbana']} name='Huerta urbana'/>} label={t('enc.g22')} />
                            
                        </FormGroup>
            </Box>
            <Divider variant="middle" />
        </Grid>



    </Grid>
  )
}
