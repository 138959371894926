import React from 'react'
import Grid from '@mui/material/Grid';

export default function InnerLabel(props) {
  return (
    <Grid  
        container
        direction="row"
        justifyContent="center"
        alignItems="center" >
                  
        <Grid item style={{fontSize:"80%", color:"black"}}>{props.text}</Grid>
        <Grid item style={{fontSize:"80%", color:"black"}}>{props.emoji}</Grid>

    </Grid>
  )
}
