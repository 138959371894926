import React from 'react'
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';


import lupa from '../assets/LUPA.png'
import { Navigate, useNavigate } from 'react-router-dom';
// import makeStyles from '@mui/styles/makeStyles';

// const useStyles = makeStyles({
//     flexGrow: {
//       flex: '1',
//     },
//     button: {
//       backgroundColor: '#3c52b2',
//       color: '#fff',
//       '&:hover': {
//         backgroundColor: '#fff',
//         color: '#3c52b2',
//     },
//   }})

export default function Buttons({value, setValue}) {

    const { t, i18n } = useTranslation();


    // const classes = useStyles()
    const navigate = useNavigate()
    const styles = {
        mainContainer: {
            backgroundColor: 'transparent',
            position: 'absolute',
            // top: '20vh',
            // left: '10vw',
            bottom: '7%',
            zIndex: '10001'
        },
        container: {
            backgroundColor: 'transparent',
            padding: '30px 10px',
            textAlign: 'center',
            fontSize: '18px',

            // border: '1px solid #000',
            // borderRadius: '0 30px 0 30px',
        },
        button: {
            border: '1px solid #000', padding: '20px 10px', color: '#000', width: '100%', fontSize: '16px',
            backgroundColor: value === 1 ? '#fdc705': '#fff'
            
        },
        button1: {
            border: '1px solid #000', padding: '20px 10px', color: '#000', width: '100%', fontSize: '16px',
            backgroundColor: value === 2 ? '#fdc705': '#fff'
            
        },
        button2: {
            border: '1px solid #000', padding: '20px 10px', color: '#000', width: '100%', fontSize: '16px',
            backgroundColor: value === 3 ? '#fdc705': '#fff'
            
        }
    }
  return (
    <Grid container style={styles.mainContainer} justifyContent="center">
            <Grid item xs={6} style={styles.container} container spacing={2}>
                <Grid item xs={4}>
                    <Button style={styles.button} onClick={() => navigate('/lugares')}  >{t('landing.options.explorar')}</Button> 
                </Grid>
                <Grid item xs={4}>
                    <Button style={styles.button1} onClick={() => navigate('/overlay')}  >{t('landing.options.compartirPunto')}</Button> 
                </Grid>
                <Grid item xs={4}>
                    <Button style={styles.button2} onClick={() => navigate('/mis-lugares')}  >{t('landing.options.verPuntos')}</Button> 
                </Grid>
                
            </Grid>



    </Grid>
  )
}
