import { useEffect, useState, useRef, useMemo } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, ImageOverlay, ZoomControl } from 'react-leaflet'
import "leaflet/dist/leaflet.css"
import L from 'leaflet'

const IconLocation =  L.icon({
    iconUrl: require('../assets/venue1.png'),
    iconRetinaUrl: require('../assets/venue1.png'),
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [40, 40],
    className: "leaflet-venue-icon"
})
export default function MapView ( ) {
    
    const [surveyDisplay, setSurveyDisplay] = useState('none')

    const [points, setPoints] = useState([])
    const [lat, setLat] = useState(0),
          [lon, setLon] = useState(0)
    
    const [selectedPosition, setSelectedPosition] = useState([0,0]);
  
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
        dragend() {
            const marker = markerRef.current
            if (marker != null) {
  
                const objectCoords = marker.getLatLng()
                setLat(objectCoords.lat)
                setLon(objectCoords.lng)
            }
        },
        }),
        [],
    )
  
    useEffect(() => {
          navigator.geolocation.getCurrentPosition(
              function(position) {
                  // // console.log(position)
                  // const converstion = Maps.newGeocoder().reverseGeocode(position.coords.latitude, position.coords.longitude)
                  // // console.log(converstion)
                  setLon(position.coords.longitude)
                  setLat(position.coords.latitude)
                  
              }, 
              function(error) {
                  // console.log(error)
              }, {
                  enableHighAccuracy: true
              })
          
  }, [])
  useEffect(() => {
    if(selectedPosition[0] !== 0 && selectedPosition[1] !== 0) 
      setPoints([selectedPosition])
        console.log(points)
  }, [selectedPosition])

    
  //Add a new point
  const Markers = () => {

    const map = useMapEvents({
        click(e) {                                
            setSelectedPosition([
                e.latlng.lat,
                e.latlng.lng
            ]);
            // console.log(e.latlng)
                         
        },            
    })
  
    
}

    

    return (
        <div className='map-box'>
            <MapContainer 
                zoomControl = {false}
                center={[19.246034, -103.718372]} // 
                zoom={13} 
                scrollWheelZoom={true}
                // onClick={addPoint}
            >
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <ZoomControl position={'bottomleft'} />
                {/* <Markers places={places}></Markers> */}
                <Marker
                draggable={true}
                eventHandlers={eventHandlers}
                ref={markerRef}
                position={[lat, lon]} 
                icon={IconLocation}
                >
                <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
                </Marker>
                <Markers />
                {
                points.map((point, index) => (
                    <Marker 
                    key={`marker-${index}`} 
                    position={point}
                    draggable={true}
                    // eventHandlers={eventHandlers}56+-
                    // ref={markerRef}
                // position={[lat, lon]} 
                    icon={IconLocation}
                >
                    <Popup>
                    <span>Tu estás aquí. <br/> Desplazate a cualquier lugar.</span>
                    </Popup>
                </Marker>
                ))
                }
                
            </MapContainer>
          </div>
    )
}