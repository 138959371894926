import React from 'react'
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';


export default function NegativeQuestion(props) {

    const { t, i18n } = useTranslation();

     //Options
     const [options, setOptions] = React.useState({
        'Altas velocidades':false,
        'Cruce peligroso/Me pueden atropellar': false,
        'No hay banqueta': false,
        'Mal estado de las vías':false,
        'Motos sobre la banqueta':false,
        'Muy oscuro': false,
        'Muy solo': false,
        'Robos': false,
        'Personas drogadas/borrachas': false,
        'Venta de drogas': false,
        'Grupos delictivos': false,
        'Personas dudosas/sospechosas': false,
        'Me miran inadecuadamente':false,
        'Me silban':false,
        'Me dicen cosas obscenas':false,
        'Han intentado tocarme':false,
        'Violencia/discriminación hacia la comunidad LGBTTTIQ':false,
        'Mucho ruido':false,
        'Basura':false,
        'Contaminación del aire/Smog':false,
        'Falta de árboles':false,
        'Malos olores':false
    });


    const handleOptions = (event) =>{

        if(event.target.checked === true && props.selections.length < 5){
            
            options[event.target.name] = true;
            props.setSelections([...props.selections,event.target.name])
            
        }else{
            options[event.target.name] = false;
            props.setSelections((state) => state.filter((item) => item !== event.target.name))
        }

        
        setOptions({...options})
    }

     //Styles
     const styles = {
        optionsBox: {
            paddingLeft: "15%"
        }
    }


    React.useEffect(()=>{
        if(props.selections.length >= 1){
            props.selections.map(
                (selopt) =>{
                    options[selopt] = true;
                    setOptions({...options})
                }
            );
        }

    },[]);

  return (
    <Grid container direction="column" style={{maxHeight:'100%', color:"black"}}>
        <Grid item>
            <Typography variant="subtitle1"> {t('enc.pp51')}</Typography>
            <Box style={styles.optionsBox}>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Altas velocidades']} name='Altas velocidades'/>} label={t('enc.h1')}/>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Cruce peligroso/Me pueden atropellar']} name='Cruce peligroso/Me pueden atropellar'/>} label={t('enc.h2')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['No hay banqueta']} name='No hay banqueta'/>} label={t('enc.h3')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Mal estado de las vías']} name='Mal estado de las vías'/>} label={t('enc.h4')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Motos sobre la banqueta']} name='Motos sobre la banqueta'/>} label={t('enc.h5')} />
                            
                        </FormGroup>
            </Box>
            <Divider variant="middle" />
        </Grid>
        

        <Grid item>
            <Typography variant="subtitle1"> {t('enc.pp52')} </Typography>
            <Box style={styles.optionsBox}>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Muy oscuro']} name='Muy oscuro'/>} label={t('enc.h6')}/>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Muy solo']} name='Muy solo'/>} label={t('enc.h7')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Robos']} name='Robos'/>} label={t('enc.h8')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Personas drogadas/borrachas']} name='Personas drogadas/borrachas'/>} label={t('enc.h9')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Venta de drogas']} name='Venta de drogas'/>} label={t('enc.h10')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Grupos delictivos']} name='Grupos delictivos'/>} label={t('enc.h11')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Personas dudosas/sospechosas']} name='Personas dudosas/sospechosas'/>} label={t('enc.h12')} />
                            
                        </FormGroup>
            </Box>
            <Divider variant="middle" />
        </Grid>

        <Grid item>
            <Typography variant="subtitle1"> {t('enc.pp53')} </Typography>
            <Box style={styles.optionsBox}>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Me miran inadecuadamente']} name='Me miran inadecuadamente'/>} label={t('enc.h13')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Me silban']} name='Me silban'/>} label={t('enc.h14')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Me dicen cosas obscenas']} name='Me dicen cosas obscenas'/>} label={t('enc.h15')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Han intentado tocarme']} name='Han intentado tocarme'/>} label={t('enc.h16')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Violencia/discriminación hacia la comunidad LGBTTTIQ']} name='Violencia/discriminación hacia la comunidad LGBTTTIQ'/>} label={t('enc.h17')} />
                            
                        </FormGroup>
            </Box>
            <Divider variant="middle" />
        </Grid>

        <Grid item>
            <Typography variant="subtitle1"> {t('enc.pp54')} </Typography>
            <Box style={styles.optionsBox}>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Mucho ruido']} name='Mucho ruido'/>} label={t('enc.h18')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Basura']} name='Basura'/>} label={t('enc.h19')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Contaminación del aire/Smog']} name='Contaminación del aire/Smog'/>} label={t('enc.h20')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Falta de árboles']} name='Falta de árboles'/>} label={t('enc.h21')} />
                            <FormControlLabel control={<Checkbox color='check' onChange={handleOptions} checked={options['Malos olores']} name='Malos olores'/>} label={t('enc.h22')} />
                            
                        </FormGroup>
            </Box>
            <Divider variant="middle" />
        </Grid>



    </Grid>
  )
}
