import React, { useState, useEffect } from 'react'
import { createTheme, useMediaQuery } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Volver from '../components/Volver';

import { useTranslation } from 'react-i18next';


// import Typography from '@mui/material/Typography';

/*** Logo IMPORT */
import img from '../assets/img2.jpg'
// import top from '../assets/signup.PNG'
import top from '../assets/fotografiadron2.png'
import Swal from 'sweetalert2'


//Data
import dataUsers from "../data.json"

// API
import { login, getSchools, signup, signupAdmin } from '../api/userAPI';

// Redux
import { deleteInfo } from '../redux/useReducer';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const CssTextFieldP = styled(OutlinedInput)({
  // '& label': {
  //   color: 'transparent'
  // },
  '& .css-1wc848c-MuiFormHelperText-root': {
    color: 'red'
  },
  '& label.MuiOuInputLabel-shrink': {
    color: 'transparent',
  },
  
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});
const CssTextField = styled(TextField)({
  // '& label': {
  //   color: 'transparent'
  // },
  '& .css-1wc848c-MuiFormHelperText-root': {
    color: 'red',
    paddingLeft: '2%',
    fontSize: '14px'
  },
  '& .Mui-error': {
    borderRadius: '100px',
    color: 'red'
  },
  '& label.MuiInputLabel-shrink': {
    color: 'transparent',
  },
  
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});
const CssSelect = styled(Select)({
   '& .MuiSelect-select': {
    '& select': {
      borderColor: 'transparent',
    },
    '&:hover select': {
      borderColor: 'transparent',
    },
    '&.Mui-focused select': {
      borderColor: 'transparent',
    },
   },
   
})

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};


export default function NewAdmin() {

  const { t, i18n } = useTranslation();


  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate();
    const dispatch = useDispatch();

  //States
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [matricula, setMatricula] = useState('');
  const [genero, setGenero] = useState('');
  const [escuela, setEscuela] = useState('');
  const [mes_anio_nacimiento, setFechaN] = useState('');
  const [codigo, setCodigo] = useState('')

  const [escuelas, setEscuelas] = useState([])
  const [open, setOpen] = useState(false);
  const [globalError, setGlobalError] = useState('');
  const [showPassword, setShowPassword] = useState(false);


  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPassword2, setErrorPassword2] = useState('');
  const [errorMatricula, setErrorMatricula] = useState('');
  const [errorGenero, setErrorGenero] = useState('');
  const [errorFechaN, setErrorFechaN] = useState('');
  const [errorEscuela, setErrorEscuela] = useState('');
  const [errorCodigo, setErrorCodigo] = useState('');


  const [loading, setLoading] = useState(false)

  
  const styles ={
    
    imglogocontainer:{
      width: '100vw',
      height: '35vh'
    },
    imglogo:{
      width: '100%',
      height: '100%'
    },
    formContainer: {
      backgroundColor: '#eeca45',
      padding: '3%',
      color: 'white',
      marginTop: '-20px'
    },
    field: {
      // width: '200%',
      color: 'white',
      backgroundColor: 'white',
      borderRadius: '100px',
    },
    fieldP: {
      // width: '160%',
      // color: 'white',
      backgroundColor: 'white',
      borderRadius: '100px',
      border: 'none',
      borderColor: 'transparent'
      
    }

    
  }

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    //Handler for submit
    const submitHandler = async () => {
      setLoading(true)
      let error = false;
    
    //Validation
    if(!validateEmail(email)){
      setErrorEmail('Email incorrecto')
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
    }
    if(password.length < 8){
      setErrorPassword('La contraseña es de al menos 8 caracteres')
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
    }
    if(password !== password2){
      setErrorPassword2('Las contraseñas no son iguales')
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
    }
    
    if(genero === '') {
      setErrorGenero('Selecciona tu género')
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
      
    }
    // if(escuela === "") {
    //   setErrorEscuela('Selecciona tu escuela')
    //   setGlobalError('Revisa el formulario, aún hay campos incorrectos')
    //   error = true;
      
    // }
    if(mes_anio_nacimiento === "") {
      setErrorFechaN('Selecciona tu fecha de nacimiento')
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
    }

    const dataUserInfo = {
        email,
        password,
        matricula,
        codigo: '00000',
        mes_anio_nacimiento,
        genero,
        escuela,

    }
    
    if(error) {
      setOpen(true)
    } else {
      console.log(dataUserInfo)
      const result = await signupAdmin(dataUserInfo)
      if(result.status == 400) {
        setOpen(true)
        setGlobalError(result.data.msg)
      } else {
        console.log(result)
        navigate(0)
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Registro exitoso!',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }

    setLoading(false)

    }

    // Handle for close
    const handleClose = () => {

        setOpen(false)

    };

    // Handle Email
    const handleEmail = (e) => {

        setEmail(e.target.value)
        setErrorEmail('')

    }
    //Handle Password
    const handlePassword = (e) => {

        setPassword((e.target.value))
        setErrorPassword('')

    }
    //Handle Password
    const handlePassword2 = (e) => {

      setPassword2((e.target.value))
      setErrorPassword2('')

  }
    // Handle Matricula
    const handleMatricula = (e) => {

        setMatricula(e.target.value)
        setErrorMatricula('')

    }
    const handleCodigo = (e) => {

      setCodigo(e.target.value)
      setErrorCodigo('')

  }
    //Handle Escuela
    const handleEscuela = (e) => {

        setEscuela((e.target.value))
        setErrorEscuela('')

    }
    // Handle FechaN
    const handleFechaN = (e) => {

        setFechaN(e.target.value)
        setErrorFechaN('')

    }
    //Handle Genero
    const handleGenero = (e) => {

        setGenero((e.target.value))
        setErrorGenero('')

    }

  
  const fetchSchools = async () => {

    const result = await getSchools()
    console.log(result)
    setEscuelas(result.data.data)

  }

  // useEffect(() => {
  //   fetchSchools()
  // }, [])


  return (
    <>
      {/* <div style={styles.imglogocontainer}>
      <img src={top} style={styles.imglogo}/>
    </div> */}
    <Grid container style={{height:"100vh", width:"100vw", margin: '0 auto'}} justifyContent="center"
    alignItems="center">


      {/** RIGHT CONTAINER */}
      
      <Grid  item container md={6}

          // direction="column"
          // fullWidth
          justifyContent="center"
          // alignItems="center" 
          // spacing={3}
          style={ styles.formContainer }
          >
        
        
        {/* <Grid item xs={12} align="center">
          <Typography style={{fontSize: isSm ? '18px' : '24px', fontWeight: 'bold', marginBottom: '10px'}} > REGÍSTRATE </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Typography style={{fontSize: isSm ? '14px' : '18px',  marginBottom: '20px'}} >Gracias por llegar hasta aquí. #ComparteLaCiudad es un proyecto social para saber sobre las actividades y lugares que como jóven frecuentas constantemente</Typography>

        </Grid> */}


        <Grid item container xs={12} spacing={2}>
          <Grid item container md={6} style={{color: '#000000', textAlign:"left", display: 'inline'}}>
          <Typography style={{fontSize: isSm ? '18px' : '24px', fontWeight: 'bold', margin: '0 auto', marginBottom: '20px'}} > Registrar un nuevo administrador </Typography>
          {/* <Typography style={{fontSize: isSm ? '14px' : '18px'}} >{t('landing.signuxp.text')}</Typography> */}

          {/* <Typography style={{fontSize: isSm ? '14px' : '18px', fontWeight: 100 }} >
            <span style={{fontWeight: 'bold'}}>Tu eres la llave del cambio</span>
          <br />
          <br />
          En Vamos Primero buscamos asegurar un futuro sostenible y equitativo, a través de la educación de calidad, la salud y el bienestar, buscando reducir el número de muertes y lesiones en niñas, niños y jóvenes en hechos viales.
          <br />
          <br />
          Mejorando la calidad de vida en las ciudades, con escalamiento en soluciones urbanas y de movilidad.</Typography> */}
         
          
          </Grid>
          <Grid item container md={6} spacing={2}>
          <Grid item xs={12} >
          <CssTextField
            color='secondary'
            style={styles.field}
            fullWidth
            required
            id="outlined-required"
            label={t('landing.signup.email')}
            type={'email'}
            onChange={handleEmail}
            value={email}
            // error={errorEmail!== ''}
            helperText={errorEmail}
            onKeyPress={(e) => e.key === "Enter" && submitHandler()}

          />
        </Grid>

        {/* <Grid item xs={12} >
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
          <CssTextFieldP
            color='secondary'
            style={styles.fieldP}
            // fullWidth
            
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePassword}
            error={errorPassword!== ''}
            
            // helperText={errorPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Contraseña"
          />
          <FormHelperText style={{color: '#d32f2f'}}>{errorPassword}</FormHelperText>
        </FormControl>
        </Grid> */}
        <Grid item xs={12} >
          <CssTextField
            color='secondary'
            style={styles.fieldP}
            fullWidth
            required
            id="outlined-adornment-password"
            label={t('landing.signup.pass')}
            type='password'
            value={password}
            onChange={handlePassword}
            // error={errorPassword!== ''}
            helperText={errorPassword}
            onKeyPress={(e) => e.key === "Enter" && submitHandler()}
            
          />
        </Grid>
        <Grid item xs={12} >
          <CssTextField
            color='secondary'
            style={styles.fieldP}
            fullWidth
            required
            id="outlined-adornment-password"
            label={t('landing.signup.pass2')}
            type='password'
            value={password2}
            onChange={handlePassword2}
            // error={errorPassword!== ''}
            helperText={errorPassword2}
            onKeyPress={(e) => e.key === "Enter" && submitHandler()}
            
          />
        </Grid>
        <Grid item xs={12}>
        <FormLabel style={{color: 'rgba(12, 17, 31, 1)'}} id="cumpleanioos">{t('landing.signup.birth')}</FormLabel>
          <CssTextField
          color='secondary'
            style={styles.field}
            fullWidth
            id="date"
            label="Fecha de Nacimiento"
            type="date"
            // defaultValue="2017-05-24"
            // sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleFechaN}
            value={mes_anio_nacimiento}
            // error={errorFechaN !== ''}
            helperText={errorFechaN}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl >
          <FormLabel style={{color: 'rgba(12, 17, 31, 1)'}} id="demo-radio-buttons-group-label">{t('landing.signup.genero')}</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="masculino"
            name="radio-buttons-group"
            onChange={handleGenero}
            value={genero}
            
          >
            <FormControlLabel value="femenino" control={<Radio sx={{color: 'white', '&.Mui-checked': {color: 'white'}}}/>} label={t('landing.signup.f')} />
            <FormControlLabel value="masculino" control={<Radio sx={{color: 'white', '&.Mui-checked': {color: 'white'}}}/>} label={t('landing.signup.m')} />
            <FormControlLabel value="otro" control={<Radio sx={{color: 'white', '&.Mui-checked': {color: 'white'}}}/>} label={t('landing.signup.otro')} />
            {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
          </RadioGroup>
        </FormControl>
        </Grid>
          {/* <Grid item xs={12}>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{t('landing.signup.escuela')}</InputLabel>
            <CssSelect
            style={{backgroundColor: 'white', borderRadius: '100px'}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={escuela}
              label="Escuela"
              onChange={handleEscuela}
              error={errorEscuela !== ''}
              // helperText={errorEscuela}
            >
            {
              escuelas.map((escuela, index) => (
                <MenuItem value={escuela.idescuela} key={index}>{escuela.nombre}</MenuItem>
              ))
            }
        </CssSelect>
        </FormControl>
        </Grid> */}
        
        <Grid item xs={23} 
          justifyContent="center"
          textAlign={'center'}
        >
          <Button 
            variant="contained"
            onClick={submitHandler}
            style={{background: 'rgba(12, 17, 31, 1)', color: 'white', borderRadius: '100px', padding: '10px 30px', margin: '0 auto 20px', fontWeight: 'bold'}}
        >{t('landing.signup.btn')}</Button>
        </Grid>
        
        {
        loading && (
          <Box sx={{ display: 'flex' }} style={{margin: '0 auto'}} >
            <CircularProgress />
          </Box>
        )
      }
          </Grid>
        </Grid>

      </Grid>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        
      >
        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>{globalError}</Alert>
      </Snackbar>

    </Grid>
    <Volver />

    </>
    
  )
}