import React from 'react'
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Grid, Tooltip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import lupa from '../assets/LUPA.png'

import log1 from '../assets/INCIARSESION_ESTADO01.png'
import log2 from '../assets/INCIARSESION.png'

import reg1 from '../assets/REGISTRO_ESTADO01.png'
import reg2 from '../assets/REGISTRO.png'
import { useTranslation } from 'react-i18next';


//Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


// import makeStyles from '@mui/styles/makeStyles';

// const useStyles = makeStyles({
//     flexGrow: {
//       flex: '1',
//     },
//     button: {
//       backgroundColor: '#3c52b2',
//       color: '#fff',
//       '&:hover': {
//         backgroundColor: '#fff',
//         color: '#3c52b2',
//     },
//   }})

export default function Buttons({handleOpen}) {

    const { t, i18n } = useTranslation();


    // const classes = useStyles()
    const navigate = useNavigate()
    const styles = {
        mainContainer: {
            backgroundColor: 'transparent',
            position: 'absolute',
            // top: '20vh',
            // left: '10vw',
            bottom: '10%',
            right: '3%',
            zIndex: '10002'
        },
        container: {
            backgroundColor: 'transparent',
            padding: '30px 10px',
            textAlign: 'center',
            fontSize: '18px',

            // border: '1px solid #000',
            // borderRadius: '0 30px 0 30px',
        },
        button: {
            border: '1px solid #000', padding: '20px 10px', color: '#000', background: '#fff', width: '100%', fontSize: '16px',
            
        }
    }
  return (
    <Grid container item xs={2} style={styles.mainContainer} justifyContent="center" >
            <Grid item container style={styles.container}  spacing={3} direction={'column'}>
                

                
                
                <Grid item container direction="row" alignItems="center" spacing={0} >
                    <Grid item container xs={2} justifyContent="flex-end">
                        <Grid item>
                            <ArrowForwardIcon></ArrowForwardIcon>
                        </Grid>
                        
                    </Grid>
                    <Grid item container xs={10} justifyContent="flex-start">
                        <Grid item>
                            <Tooltip placement="top-start" title={t("landing.buttons.code")}>
                            <IconButton onClick={handleOpen}>
                                <img src={reg1} width={60} height={60} />
                            </IconButton>
                            </Tooltip>
                        </Grid>
                        
                    </Grid>
                </Grid>
                
                
                
            </Grid>



    </Grid>
  )
}
