import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import i18n from 'i18next';

// import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

import global_es from './translations/es/global.json'
import global_en from './translations/en/global.json'


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: global_en,
  es: global_es
};

i18n  
  //  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  //  .use(LanguageDetector)
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

console.log(i18n)
  export default i18n;