import React from 'react'
import './overlay.css'
//Images
import backgroundSurvey from '../assets/encuesta/cajasencuestas.png'

import DesignSurvey from '../components/DesignSurvey'

export default function TestSurvey( props) {
    //const [surveyDisplay, setSurveyDisplay] = React.useState('block')
    const {surveyDisplay, setSurveyDisplay} = props;
    
    //const [markerFlag, setMarkerFlag] = React.useState(false)
    const {markerFlag, setMarkerFlag} = props;
    
    //const [activeStep, setActiveStep] = React.useState(-1);
    const { activeStep, setActiveStep } = props;

    //const [chooseLoc, setChooseLoc] = React.useState([]);
    const {chooseLoc, setChooseLoc} = props;
    
    const [cardTitle, setCardTitle] = React.useState('');

    React.useEffect(()=>{
        switch(activeStep){
            case 0:
                setCardTitle('Selecciona un punto en el mapa')
            break;
            case 1:
                setCardTitle('¿Cómo llegas a este lugar?')
            break;
            case 2:
                setCardTitle('¿Cómo te sientes en este lugar?')
            break;
            case 3:
                setCardTitle('Me siento así por:')
            break;
            case 4:
                setCardTitle('Organiza por prioridad')
            break;
            case 5:
                setCardTitle('¿Tienes algo más que decir sobre este lugar? (opcional)')
            break;
            case 6:
                setCardTitle('Sube una foto del lugar (opcional)')
            break;
            case 7:
                setCardTitle('¡Gracias por participar!')
            break;
            default:
                setCardTitle('')
                break;
        }
    },[activeStep]);

  return (
    <>
    <div>
        <div className='cardtitle' style={{display: surveyDisplay}}>

            <div className='innerCardTitle' >
                {cardTitle}
            </div>
            
        </div>

        <div className='cardcontent' style={{display: surveyDisplay, backgroundImage: `url(${backgroundSurvey})` }} >
                <DesignSurvey setMarkerFlag={setMarkerFlag} surveyDisplay={surveyDisplay} setSurveyDisplay={setSurveyDisplay} activeStep={activeStep} setActiveStep={setActiveStep} chooseLoc={chooseLoc} setChooseLoc={setChooseLoc}></DesignSurvey>
                
                
        </div>
    </div>
    </>
  )
}
