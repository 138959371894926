
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#700473',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: 'rgba(20,20,20,1)',
    },
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: 'rgba(0,0,0,1)',
    },
    error: {
      main: '#d32f2f',
    },
    check: {
      main: '#ffc100'
    }
  },
  typography: {
    "fontFamily": `"Rubik", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
 
});

export default theme;