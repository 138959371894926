import React from 'react'
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import vamosp from '../assets/logoblancoof.png'
import botnar from '../assets/logo_BOTNAR.png'

export default function Top() {

    const { t, i18n } = useTranslation();


    const styles = {
        mainContainer: {
            backgroundColor: 'transparent',
            position: 'absolute',
            // top: '20vh',
            // left: '10vw',
            // bottom: '10%',
            zIndex: '10000'
        },
        container: {
            backgroundColor: '#fdc705',
            padding: '1px 20px',
            textAlign: 'center',
            fontSize: '18px'
        }
    }
  return (
    <Grid container style={styles.mainContainer} justifyContent="center">
            <Grid item container  xs={4} style={styles.container} justifyContent="center" alignItems="center">
                <Grid item xs={2}>
                    {/* <img src={botnar} width={"100%"} height={"100%"}/>  */}
                </Grid>
                <Grid item xs={8}>
                    <Typography fontSize={'18px'} >{t('landing.title')}</Typography>
                </Grid>
                <Grid item xs={2}>
                    {/* <img src={vamosp} width={"90%"} height={"100%"} />  */}
                </Grid>
                 
            </Grid>
            



    </Grid>
  )
}
