import React from 'react'
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import lupa from '../assets/LUPA.png'
import signo from '../assets/SIGNO.png'
export default function Bar({text, value}) {


    const styles = {
        mainContainer: {
            backgroundColor: 'transparent',
            position: 'absolute',
            // top: '20vh',
            // left: '10vw',
            bottom: '20%',
            zIndex: '10000'
        },
        container: {
            backgroundColor: '#fff',
            padding: '15px 5px',
            textAlign: 'center',
            fontSize: '18px',
            border: '1px solid #000',
            borderRadius: '0 30px 0 30px',
        }
    }
  return (
    <Grid container style={styles.mainContainer} justifyContent="center">
            <Grid item xs={6} style={styles.container} container>
                <Grid item xs={2}>
                    <img src={value > 1 ? signo : lupa} width={50} height={50}/>
                </Grid>
                <Grid item xs={10} textAlign="left">
                <Typography fontSize={'18px'} >{text}</Typography>
                </Grid>
            </Grid>



    </Grid>
  )
}
