import React from 'react'
import PropTypes from 'prop-types'

import DragHandleIcon from '@mui/icons-material/DragHandle';

import './card.css'

const CardList = props => {
  return (
    <div className='card' >
        
        <div>
            <DragHandleIcon/>
        </div>
        <div>
            {props.item}
        </div>
    </div>
  )
}

CardList.propTypes = {
    item: PropTypes.string
}

export default CardList