import React from 'react'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next';

// import { MapContainer } from 'react-leaflet/MapContainer'
// import { TileLayer } from 'react-leaflet/TileLayer'
import { useDispatch, useSelector } from 'react-redux'

import { useMap } from 'react-leaflet/hooks'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, ZoomControl } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
// import 'leaflet/dist/leaflet.css'
import './overlay.css'

//Components
import { IconLocation } from '../components/IconLocation'
import { IconLocationRed } from '../components/IconLocationRed'
import { PointLocation } from '../components/PointLocation'
import Markers from '../components/Markers'
import places from '../assets/lugares.json'
import { getAllPlaces, getImage, getMyPlaces } from '../api/userAPI'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Top from '../components/Top'
import Bar from '../components/Bar'
import Buttons from '../components/ButtonsPrivate'
import ButtonsActions from '../components/ButtonsActionspPrivate'

import { MyPlanet } from '../components/MyPlanet'

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import mundo from '../assets/mundo.png'

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';



export default function MySites() {

  const userData = useSelector((store) => store.user)

  // const [points, setPoints] = useState([
  //   [19.5158757, -96.1345574],
  //   [18.5158767, -99.1345674],
  //   [16.5158777, -99.1345774],
  //   [13.5158787, -99.1345874],
  // ])

  const localData = JSON.parse(localStorage.getItem('data')) || {lng:"es",country:"es-2"};
  const localData2 = JSON.parse(localStorage.getItem('coords')) || {lat:0, lon:0 };



  const [surveyDisplay, setSurveyDisplay] = React.useState('none')
  const [markerFlag, setMarkerFlag] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(-1);

  const [points, setPoints] = useState([])
  const [lat, setLat] = useState(localData2.lat),
        [lon, setLon] = useState(localData2.lon),
        [image, setImage] = useState('')
  
        const [code, setCode] = useState('')

  const [selectedPosition, setSelectedPosition] = useState([0,0]);

  const [value, setValue] = useState(3)

  const [country, setCountry] = useState(localData.country);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCode('')
    setOpen(false);
  };
  const handleSubmit = (e) => {
    console.log(code)
    e.preventDefault()
    alert(`Code: ${code}`)
    handleClose()
  }

  const handleCountry = (event) => {
    setCountry(event.target.value);
  };


  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const markerRef = useRef(null)
  const eventHandlers = useMemo(
      () => ({
      dragend() {
          const marker = markerRef.current
          if (marker != null) {

              const objectCoords = marker.getLatLng()
              setLat(objectCoords.lat)
              setLon(objectCoords.lng)
          }
      },
      }),
      [],
  )

  useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            function(position) {
                // // console.log(position)
                // const converstion = Maps.newGeocoder().reverseGeocode(position.coords.latitude, position.coords.longitude)
                // // console.log(converstion)
                setLon(position.coords.longitude)
                setLat(position.coords.latitude)
                
            }, 
            function(error) {
                // console.log(error)
            }, {
                enableHighAccuracy: true
            })
        
}, [])
// useEffect(() => {
//   if(selectedPosition[0] !== 0 && selectedPosition[1] !== 0) 
//     setPoints([...points, selectedPosition])

// }, [selectedPosition])

const fetchPlaces = async() => {

  const response = await getMyPlaces()
  // console.log(response)

  const newPoints = response.data.data.map(place => place)
  console.log(newPoints)
  setPoints(newPoints)
}

const onClick = async (id) => {
  
  const response = await getImage({key: id})
  if(response.data && response.data.url) setImage(response.data.url)
}
useEffect(() => {

  fetchPlaces()

}, [])




  //Functions

  //Add a new point
  const Markers = () => {

    const map = useMapEvents({
        click(e) {                                
            setSelectedPosition([
                e.latlng.lat,
                e.latlng.lng
            ]);
            // console.log(e.latlng)
                         
        },            
    })
  
    
}

  return (
    
  <>
    {
      lat && lon ? (
        // <div id="map" style={{height:"50%", width:"50%", backgroundColor:"red"}}>
          <>
          <Top />
          <Box sx={{ minWidth: 120 }}>
         <Dialog open={open} onClose={handleClose} fullWidth={false}
             maxWidth={'xs'} style={{zIndex: '200000'}}>
        <DialogTitle>{t("landing.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {t("landing.dialog.body")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="code"
            label={t("landing.dialog.codigo")}
            type="tesxt"
            fullWidth
            variant="standard"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("landing.dialog.cancelar")}</Button>
          <Button onClick={handleSubmit}>{t("landing.dialog.empezar")}</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ minWidth: 150 }} style={{ backgroundColor: 'transparent',
            position: 'absolute', right: '20px',zIndex: '10001', margin: 20, borderRadius: '10px'}}>
            <FormControl  style={{ backgroundColor: 'transparent', color: 'white', borderRadius: '10px'}} fullWidth>
              <InputLabel id="demo-simple-select-label">{t("landing.languages.idiomas")}</InputLabel>
              <Select

                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={country}
                label="Age"
                onChange={handleCountry}
                style={{border: 'transparent', backgroundColor: '#9f62a7', color: 'white'}}
              >
                                
                <MenuItem onClick={() => changeLanguage("es")} value={'es-2'}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={4}>
                <img src={mundo} width={30} height={30}></img>                
                </Grid>
                <Grid item xs={7}>
                  {t("landing.languages.español")}               
                </Grid>
                 
            </Grid>
                 
                  
                </MenuItem>
                <MenuItem onClick={() => changeLanguage("en")} value={'en-1'}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={4}>
                <img src={mundo} width={30} height={30}></img>
                </Grid>
                <Grid item xs={7}>
                {t("landing.languages.ingles")}
                </Grid>
                 
            </Grid>
                </MenuItem>
              </Select>
            </FormControl>
           </Box>
         
    </Box>
    <Bar text={value === 1 ? t('landing.textOptions.text1') : value === 2 ? t('landing.textOptions.text2') : t('landing.textOptions.text3')}value={value} />
         <Buttons value={value} setValue={setValue}/>
         <ButtonsActions />
            <MapContainer 
            center={[lat, lon]} // 
            zoom={13} 
            scrollWheelZoom={true}
            zoomControl={false}
            // onClick={addPoint}
          >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            <ZoomControl position={'bottomleft'} />
            {/* <Markers places={places}></Markers> */}
            {/* <Marker
              draggable={true}
              eventHandlers={eventHandlers}
              ref={markerRef}
              position={[lat, lon]} 
              icon={IconLocation}
            >
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker> */}
            <Markers />
            {
              points.map((point, index) => (
                <Marker
                eventHandlers={{ click: () => onClick(point.reference) }}
                  key={`marker-${index}`} 
                  position={[point.latitud, point.longitud]}
                  draggable={false}
                  // eventHandlers={eventHandlers}
                  // ref={markerRef}
              // position={[lat, lon]} 
                  icon={point.como_sientes_en_el_lugar === 'Me gusta' ||
                  point.como_sientes_en_el_lugar === 'Me siento segura/o' || 
                  point.como_sientes_en_el_lugar === 'Me encanta' ? IconLocation : IconLocationRed}
              >
                <Popup
                >
                <Card sx={{ minWidth: 300 }}>
               <CardMedia
                 component="img"
                 alt="img"
                 height="140"
                 image={image}
               />
               <CardContent>
                <span style={{fontWeight: 'bold', fontSize: '18px'}}>Sensación: </span><span style={{fontSize: '16px'}}>{point.como_sientes_en_el_lugar}</span>
                <br />
                <br />
                <span style={{fontWeight: 'bold', fontSize: '18px'}}>Razones: </span>
                <br />
                {
                  
                  point.caracteristicas.split(',').map((item, i) => (
                    <li style={{fontSize: '16px'}}>{i+1}.- {item}</li>
                  )) 
                  
                }
                
               </CardContent>
       
             </Card>
                </Popup>
              </Marker>
              ))
            }
          </MapContainer>
          {/* <div className='data-box-menu' >
            <MyPlanet surveyDisplay={surveyDisplay} setSurveyDisplay={setSurveyDisplay} setMarkerFlag={setMarkerFlag} setActiveStep={setActiveStep}></MyPlanet>
            
          </div> */}
          </>
        // </div>
      ) : <>
      <Top />
      <Box sx={{ minWidth: 120 }}>
         <Dialog open={open} onClose={handleClose} fullWidth={false}
             maxWidth={'xs'} style={{zIndex: '200000'}}>
        <DialogTitle>{t("landing.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {t("landing.dialog.body")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="code"
            label={t("landing.dialog.codigo")}
            type="tesxt"
            fullWidth
            variant="standard"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("landing.dialog.cancelar")}</Button>
          <Button onClick={handleSubmit}>{t("landing.dialog.empezar")}</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ minWidth: 150 }} style={{ backgroundColor: 'transparent',
            position: 'absolute', zIndex: '10001', margin: 20, borderRadius: '10px'}}>
            <FormControl  style={{ backgroundColor: 'transparent', color: 'white', borderRadius: '10px'}} fullWidth>
              <InputLabel id="demo-simple-select-label">{t("landing.languages.idiomas")}</InputLabel>
              <Select

                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={country}
                label="Age"
                onChange={handleCountry}
                style={{border: 'transparent', backgroundColor: '#9f62a7', color: 'white'}}
              >
                                
                <MenuItem onClick={() => changeLanguage("es")} value={'es-2'}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={4}>
                <img src={mundo} width={30} height={30}></img>                
                </Grid>
                <Grid item xs={7}>
                  {t("landing.languages.español")}               
                </Grid>
                 
            </Grid>
                 
                  
                </MenuItem>
                <MenuItem onClick={() => changeLanguage("en")} value={'en-1'}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={4}>
                <img src={mundo} width={30} height={30}></img>
                </Grid>
                <Grid item xs={7}>
                {t("landing.languages.ingles")}
                </Grid>
                 
            </Grid>
                </MenuItem>
              </Select>
            </FormControl>
           </Box>
         
    </Box>
      <Bar text={value === 1 ? t('landing.textOptions.text1') : value === 2 ? t('landing.textOptions.text2') : t('landing.textOptions.text3')}value={value} />
     <Buttons value={value} setValue={setValue}/>
     <ButtonsActions />
        <MapContainer 
        zoomControl={false}
        center={[19.246034, -103.718372]} // 
        zoom={13} 
        scrollWheelZoom={true}
        // onClick={addPoint}
      >
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          <ZoomControl position={'bottomleft'} />
        {/* <Markers places={places}></Markers> */}
        {/* <Marker
          draggable={true}
          eventHandlers={eventHandlers}
          ref={markerRef}
          position={[lat, lon]} 
          icon={IconLocation}
        >
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker> */}
        <Markers />
        {
          points.map((point, index) => (
            <Marker
            eventHandlers={{ click: () => onClick(point.reference) }}
              key={`marker-${index}`} 
              position={[point.latitud, point.longitud]}
              draggable={false}
              // eventHandlers={eventHandlers}
              // ref={markerRef}
          // position={[lat, lon]} 
              icon={point.como_sientes_en_el_lugar === 'Me gusta' ||
              point.como_sientes_en_el_lugar === 'Me siento segura/o' || 
              point.como_sientes_en_el_lugar === 'Me encanta' ? IconLocation : IconLocationRed}
          >
            <Popup
            >
            <Card sx={{ minWidth: 300 }}>
           <CardMedia
             component="img"
             alt="img"
             height="140"
             image={image}
           />
           <CardContent>
            <span style={{fontWeight: 'bold', fontSize: '18px'}}>Sensación: </span><span style={{fontSize: '16px'}}>{point.como_sientes_en_el_lugar}</span>
            <br />
            <br />
            <span style={{fontWeight: 'bold', fontSize: '18px'}}>Razones: </span>
            <br />
            {
              
              point.caracteristicas.split(',').map((item, i) => (
                <li style={{fontSize: '16px'}}>{i+1}.- {item}</li>
              )) 
              
            }
            
           </CardContent>
   
         </Card>
            </Popup>
          </Marker>
          ))
        }
      </MapContainer>
      {/* <div className='data-box-menu' >
        <MyPlanet surveyDisplay={surveyDisplay} setSurveyDisplay={setSurveyDisplay} setMarkerFlag={setMarkerFlag} setActiveStep={setActiveStep}></MyPlanet>
        
      </div> */}
      </>
    }
  </>

  )
}
