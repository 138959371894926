import L from "leaflet";
import iconito from "../assets/verde.png"

export const PointLocation = L.icon({
    //iconUrl: require("../assets/venue_location_icon.svg"),
    iconUrl: iconito,
    iconRetinaUrl: iconito,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [20,20],
    className: "leaflet-venue-icon"

}) 