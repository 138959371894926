import React, { useState, useEffect } from 'react'
import { createTheme, useMediaQuery } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
//MUI
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField'

import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button'
import Volver from '../components/Volver';




import MUIDataTable from "mui-datatables";







import Swal from 'sweetalert2'


import { login, getSchools, signup, getMapeos, download, getUsuarios, deleteUsuario, updateUsuario } from '../api/userAPI';

// Redux
import { deleteInfo } from '../redux/useReducer';


const data = [
 ["Joe James", "Test Corp", "Yonkers", "NY"],
 ["John Walsh", "Test Corp", "Hartford", "CT"],
 ["Bob Herm", "Test Corp", "Tampa", "FL"],
 ["James Houston", "Test Corp", "Dallas", "TX"],
];




const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const CssTextField = styled(TextField)({
  // '& label': {
  //   color: 'transparent'
  // },
  '& .css-1wc848c-MuiFormHelperText-root': {
    color: 'red',
    paddingLeft: '2%',
    fontSize: '14px'
  },
  '& .Mui-error': {
    borderRadius: '100px',
    color: 'red'
  },
  '& label.MuiInputLabel-shrink': {
    color: 'transparent',
  },
  
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});


export default function Usuaarios() {

  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [genero, setGenero] = useState('');
  const [mes_anio_nacimiento, setFechaN] = useState('');
  const [open, setOpen] = useState(false);

  

  const [data, setData] = useState([]);
  const [usuarios, setUsuarios] = useState([])

  const [isNew, setIsnew] = useState(true);

  const [nombre, setNombre] = useState('')
  const [siglas, setSiglas] = useState('')
  const [idToEdit, setIdToEdit] = useState(0)

  const [clienteId, setClienteId] = useState(0)


  const navigate = useNavigate();

 

  const options = {
    selectableRows: false,
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  const { t, i18n } = useTranslation();


  
  const styles ={
    
    imglogocontainer:{
      width: '100vw',
      height: '35vh'
    },
    imglogo:{
      width: '100%',
      height: '100%'
    },
    formContainer: {
      backgroundColor: '#eeca45',
      padding: '3%',
      color: 'white',
      marginTop: '-20px'
    },
    field: {
      // width: '200%',
      color: 'white',
      backgroundColor: 'white',
      borderRadius: '100px',
    },
    fieldP: {
      // width: '160%',
      // color: 'white',
      backgroundColor: 'white',
      borderRadius: '100px',
      border: 'none',
      borderColor: 'transparent'
      
    }

    
  }

  const clear = () => {
    setGenero('')
    setEmail('')
    setEmail('')
    setFechaN('')
  }

  const handleClose = () => {
    setOpen(false);
    clear();
  };

  const handleClickOpen = () => {
    setOpen(true);

    
  };

   // Handle Email
   const handleEmail = (e) => {

    setEmail(e.target.value)

}
//Handle Password
const handlePassword = (e) => {

    setPassword((e.target.value))

}
const handleFechaN = (e) => {

  setFechaN(e.target.value)

}
//Handle Genero
const handleGenero = (e) => {

  setGenero((e.target.value))

}
  const fetchUsuarios = async () => {

    const result = await getUsuarios()
    console.log(result)
    const normalData = result.data.data;
    const dataDisplay = normalData.map(item => [item.idusuario, item.email, item.titulo, item.codigo, item.genero, item.mes_anio_nacimiento]);

   setData(dataDisplay);
   setUsuarios(normalData)

  }

  useEffect(() => {
   fetchUsuarios()
  }, [])


  const updateDataUser = async () => {

    const data = {
      id: idToEdit,
      email,
      password,
      mes_anio_nacimiento,
      genero
    }

    try {
      const result = await updateUsuario(data)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Usuario editado exitosamente!',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (error) {
      console.log(error)
    }
    navigate(0)
  }


  const editItem  = async(id) => {


    const {email, genero, contrasenia, mes_anio_nacimiento} = usuarios.find(usuario => usuario.idusuario == id)

    setEmail(email)
    setPassword(contrasenia)
    setGenero(genero)
    setFechaN(mes_anio_nacimiento)
    setIdToEdit(id)

    handleClickOpen()

    
  }
  const deleteUsuario1 = async (id) => {
    
    const data = {
      id
  }

  try {
    const result = await deleteUsuario(data)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Usuario eliminado exitosamente!',
      showConfirmButton: false,
      timer: 1500
    })
  } catch (error) {
    console.log(error)
  }
  navigate(0)


  }
  const chooseDelete = async(id) => {
  
    Swal.fire({
      title: '¿Estas seguro que quieres borrar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, seguro!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUsuario1(id)
        Swal.fire(
          'Eliminado!',
          '',
          'success'
        )
      }
    })
  }
    
 
  const columns = [
    "ID",
    {name: "Email", options: { filterOptions: { fullWidth: true } } },
    "Título mapeo",
    "Título código Mapeo",
    "Género",
    "Fecha de nacimiento",
    {
      name: "Editar",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
                                        onClick={() => editItem(tableMeta.rowData[0])}
                                    >
                                        <Edit />
                                    </IconButton>
          );
        }
      }
    },
    {
      name: "Eliminar",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
                                        onClick={() => chooseDelete(tableMeta.rowData[0])}
                                    >
                                        <Delete/>
                                    </IconButton>
          );
        }
      }
    },
    
  ];


  return (
    <>
      {/* <div style={styles.imglogocontainer}>
      <img src={top} style={styles.imglogo}/>
    </div> */}
    <Grid container justifyContent="center"
    alignItems="center">


      <Grid item md={12} container justifyContent="center"
    alignItems="center">
      <Typography variant="h1" gutterBottom>
        Usuarios
      </Typography>
      </Grid>

      <Grid item>
        <MUIDataTable
          title={"Usuarios"}
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>

    </Grid>
    <Dialog open={open} onClose={handleClose} 
        maxWidth={'md'}>
        <DialogTitle>{ 'Editar registro'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingresa todos los campos necesarios para editar 
          </DialogContentText>

          <Grid item container xs={12} spacing={2}>
            <Grid item container md={6} spacing={2}>
              <Grid item xs={12} >
              <TextField
                fullWidth
                required
                id="outlined-required"
                label={'Email'}
                type={'email'}
                onChange={handleEmail}
                value={email}
                // error={errorEmail!== ''}

              />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  fullWidth
                  required
                  id="outlined-adornment-password"
                  label={'Contraseña'}
                  type='text'
                  value={password}
                  onChange={handlePassword}
                  
                />
              </Grid>
              <Grid item xs={12}>
              <FormLabel style={{color: 'rgba(12, 17, 31, 1)'}} id="cumpleanioos">Fecha de Nacimiento</FormLabel>
                <TextField
                  fullWidth
                  id="date"
                  label="Fecha de Nacimiento"
                  type="date"
                  // defaultValue="2017-05-24"
                  // sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleFechaN}
                  value={mes_anio_nacimiento}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl >
                <FormLabel style={{color: 'rgba(12, 17, 31, 1)'}} id="demo-radio-buttons-group-label">Género</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="masculino"
                  name="radio-buttons-group"
                  onChange={handleGenero}
                  value={genero}
                  
                >
                  <FormControlLabel value="femenino" control={<Radio sx={{color: 'white', '&.Mui-checked': {color: 'white'}}}/>} label={t('landing.signup.f')} />
                  <FormControlLabel value="masculino" control={<Radio sx={{color: 'white', '&.Mui-checked': {color: 'white'}}}/>} label={t('landing.signup.m')} />
                  <FormControlLabel value="otro" control={<Radio sx={{color: 'white', '&.Mui-checked': {color: 'white'}}}/>} label={t('landing.signup.otro')} />
                  {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                </RadioGroup>
              </FormControl>
              </Grid>

            </Grid>
          </Grid>


      
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={updateDataUser}>{ 'Editar'}</Button>
        </DialogActions>
      </Dialog>
      <Volver />

    </>
    
  )
}