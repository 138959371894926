import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Redux
import { getUserInfo } from '../redux/useReducer'

// API
import { validateLogin } from '../api/auth'


const PrivateRoute = props => {
  const { Component } = props
  const dispatch = useDispatch()
  const userData = useSelector((store) => store.user)

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [render, setRender] = useState(false)


  // Effects
  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  useEffect(() => {
    validateLogin(userData, setIsLoggedIn, setRender)
    /* eslint-disable-next-line */
  }, [userData]);


  return (
    render ? (
      isLoggedIn ? (
        <Component />
      ) : (
        <Navigate
          to='/'
          state={{ msg: 'Para acceder primero debe iniciar sesión', type: 'warning' }}
          replace={true}
        />
      )
    ) : (
      <></>
    )
  )
}

export default PrivateRoute