import React from 'react'
import './designsurvey.css'
import { useTranslation } from 'react-i18next';


//MUI
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton  from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText';

//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';



//Components
// import TransportsRadios from '../components/SurveyComp/TransportsRadios'
import TransportsRadios from '../components/SurveyComp/TransportsRadiosNew'
import PlaceFeeling from '../components/SurveyComp/PlaceFeeling';
import NegativeQuestion from '../components/NegativeQuestion';
import PositiveQuestion from '../components/PositiveQuestion';
import ListComponent from '../components/ListComponent/ListComponent'
import DropBox from '../components/ImageDrop/DropBox';

// API
import { uploadFilesToServer, newLocation } from '../api/userAPI'

//Extra
import { useNavigate } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary
  }));



export default function TestDesignSurvey(props) {
    const navigate = useNavigate();
    const setSurveyDisplay = props.setSurveyDisplay
    const [dynamicHeight, setDynamicHeight] = React.useState('')
    
    const [ activeStep, setActiveStep ] = React.useState(0);
    const [isPositive, setIsPositive] = React.useState(undefined);
    const [selections, setSelections] = React.useState([]);

    const [transport, setTransport] = React.useState([]);
    const [radioValue, setRadioValue] = React.useState('');
    
    
    const [description, setDescription] = React.useState('');
    const [fileList, setFileList] = React.useState([]);
    
    const [height, setHeight] = React.useState(0);
    const { t, i18n } = useTranslation();
    console.log({t})


    //Refs
    const refStep1 = React.useRef();
    const refStep2 = React.useRef();
    const refStep3 = React.useRef();
    const refStep4 = React.useRef();
    const refStep5 = React.useRef();
    const refStep6 = React.useRef();


    //Handlers

    const forwardStep = () => {

      console.log(selections)

      if(activeStep === 2){
        if (radioValue === 'No me gusta' || radioValue === 'Me siento en peligro' || radioValue === 'Me da tristeza'){
          setIsPositive(false)
          //setSelections([])
          
        }else if (radioValue === 'Me gusta' || radioValue === 'Me siento segura/o' || radioValue === 'Me encanta'){
          setIsPositive(true)
          //setSelections([])
        }
      }
        
          console.log(props.chooseLoc[0])
        if(activeStep === 0 && (props.chooseLoc[0] === undefined && props.chooseLoc[1] === undefined) ){
          alert(t('enc.ttx1'))
        }
        else if(activeStep === 1 && transport.length === 0){
          alert(t('enc.ttx2'))
        }
        else if(activeStep === 2 && radioValue === '') {
          alert(t('enc.ttx3'))
        }
        else if(activeStep === 3 && (selections.length < 1 || selections.length > 5)){
          if(selections.length < 1){
            alert(t('enc.ttx4'))
          }else{
            alert(t('enc.ttx5'))
          }
        }
        else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } 
        
        getDimensions()
    }

    const backWardStep = () =>{
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        
        getDimensions()
    
    }

    const handleTransports = (event) =>{
        setTransport(event.target.value)
    }

    /** On drop callback function to get the images from the dropbox */
    const onDrop = React.useCallback((acceptedFiles) => {
    
        /** Mapping the incoming accepted files and saving them in the file list */
        acceptedFiles.map((list,index) => {
        
        setFileList((prevState) => [
            ...prevState,
            { id: index, path:  list.path, size: list.size, extension: list.type, content:list},
            ])

        });

        
        
    }, []);

    const deleteFileFromList = (path)=>{
        //Find file index in the list thorugh its path
        var index = fileList.findIndex(e => e.path === path);
        //If index if found, delete the file by slicing the list, this process is done this way because it don't mutates the state
        if(index !== -1){
          
          setFileList([
            ...fileList.slice(0, index),
            ...fileList.slice(index + 1)
          ]);
    
        }
    
      }

      const handleFinish = ()=>{
        //console.log('entra')
        setSurveyDisplay('none')
        setActiveStep(0)
        return navigate('/mis-lugares')
    }

          /** Function that handles the upload of the files in the filesList state variable */
          const uploadFiles = async ()=>{
      
            /*
            console.log("entra")
            setErrorExercise(!exerciseSelection)
            setErrorStation(!stationSelection)
            
            if(fileList.length === 0){
              setErrorFiles(true);
              setModalMessage({msg : "Se debe seleccionar al menos un archivo", color: "#ff0000"});
            }else{
              setErrorFiles(false);
            }
            console.log("Error exercise value " + errorExercise)
            console.log("Error station value " + errorStation)
            console.log("Longitud de la lista" + fileList.length)
            console.log("Eror files "+ errorFiles)
            */
            // if(( errorStation === false && errorFiles === false) &&  fileList.length !== 0){
              
              // const formData = new FormData(); //Create a new form-data
            //   const config2 = { headers: { 'Content-Type': 'multipart/form-data' } }; //headers
      
            //   //Iterate through the list and get the value of the content key wich stores the file, then append each file to the form under the upfiles key
              // fileList.map( (f) =>{
                
              //   formData.append('upfiles', f.content);
              // });
              
            // //Append idEstacion and idEjercicio to the form 
            // formData.append('idEstacion', stationSelection);
      
            // if(exerciseSelection == '' || exerciseSelection == null){
            //   formData.append('idEjercicio', null);
            // }else{
            //   formData.append('idEjercicio', exerciseSelection);
            // }
            
      
            
              
            // /** Axios post request to backend server  */
            // /*
            // axios.post('https://127.0.0.1:4000',  formData, config2 )
            //         .then((result) => {
            //             console.log(result.data);
            //             alert('Archivos cargados');
            //             closeModal()
            //         })
            //         .catch((error) => {
            //             console.log(error);
            //             console.log(error.response)
            //         })*/
            
            //         await uploadFilesToServer(formData).then((result) => {
            //           console.log(result.data);
            //           //alert('Archivos cargados');
            //           setIsLoading(false)
            //           closeModal()
            //           handleRefresh()
            //           setMessage({ open: true, msg: result.data.msg, key: new Date(), type:"success" })
            //       }).catch((error) => {
            //           console.log(error);
            //           console.log(error.response)
            //           setMessage({ open: true, msg: error.response.data.msg, key: new Date(), type:"error" })
            //       })
            //         //console.log(response)
            
            
            // }
            try {
              if(  fileList.length !== 0){
                // API
                var data = {
                  lat: props.chooseLoc[0],
                  long: props.chooseLoc[1],
                  transportmethods: transport,
                  feeling: radioValue,
                  features: selections,
                  description: description,
                  upfiles: fileList.map(file => file.content),
                }
      
                
                
                console.log(data);
                const response = await uploadFilesToServer(data)
                console.log(response)
                //closeModal()
                //handleRefresh()
                //setMessage({ open: true, msg: response.data.msg, key: new Date(), type:"success" })
              }else{
                var dataPayload = {
                    lat: props.chooseLoc[0],
                    long: props.chooseLoc[1],
                    transportmethods: transport,
                    feeling: radioValue,
                    features: selections,
                    description: description,
                    
                  }
                const response = await newLocation(dataPayload)
                console.log(response)
              }
            } catch (error) {
              //setMessage({ open: true, msg: error.data.msg, key: new Date(), type:"error" })
              console.log(error)
            }
            
          }

    const showButton = e => {
        e.preventDefault();
        //setDisplay("displayed");
        //e.clientX
        //clientY
        //pageX
        //target.clientHeight
        //nativeEvent.clientX

        //nativeEvent.fromElement.clientHeight
        //fromElement.offsetHeight
        //fromElement.offsetTop
        //fromElement.layerY
        //fromElement.offsetY
        //fromElement.pageY
        //fromElement.screenY


        //console.log(e)
        //console.log(e.nativeEvent)
        //setDynamicHeight((e.nativeEvent.clientY - e.nativeEvent.offsetY)+'px')
        setDynamicHeight(e.nativeEvent.fromElement.clientHeight +'px')
      };
    
      const hideButton = e => {
        e.preventDefault();
        //setDisplay("notdisplayed");
      };

      const clickedItem = e =>{
        e.preventDefault();
        //console.log(e)
        setDynamicHeight((e.clientY) +'px')
      }

      const getDimensions = ()=>{

        
        if(activeStep < 7){

        
        
        switch(activeStep){
            case 1:
                setDynamicHeight(refStep1.current.getBoundingClientRect().y+'px');
                break;
            case 2:
                setDynamicHeight(refStep2.current.getBoundingClientRect().y+'px');
                break;
            case 3:
                setDynamicHeight(refStep3.current.getBoundingClientRect().y+'px');
                break;
            case 4:
                setDynamicHeight(refStep4.current.getBoundingClientRect().y+'px');
                break;
            case 5:
                setDynamicHeight(refStep5.current.getBoundingClientRect().y+'px');
                break;
            case 6:
                setDynamicHeight(refStep6.current.getBoundingClientRect().y+'px');
                break;
            default:
                setDynamicHeight(refStep1.current.getBoundingClientRect().y+'px');
                break;   
        }
      }
        //console.log(height)
      }

     // EFFECTS
     
    React.useEffect(()=>{
        getDimensions()
        window.addEventListener("resize", getDimensions);

    },[]);



    React.useEffect(()=>{

        //Dynamicaly change the submenu position
        if(activeStep < 7){

        switch(activeStep){
            case 1:
                setDynamicHeight(refStep1.current.getBoundingClientRect().y+'px');
                break;
            case 2:
                setDynamicHeight(refStep2.current.getBoundingClientRect().y+'px');
                break;
            case 3:
                setDynamicHeight(refStep3.current.getBoundingClientRect().y+'px');
                break;
            case 4:
                setDynamicHeight(refStep4.current.getBoundingClientRect().y+'px');
                break;
            case 5:
                setDynamicHeight(refStep5.current.getBoundingClientRect().y+'px');
                break;
            case 6:
                setDynamicHeight(refStep6.current.getBoundingClientRect().y+'px');
                break;
            default:
                setDynamicHeight(refStep1.current.getBoundingClientRect().y+'px');
                break;   
        }
      }
        //
        // Update the document title using the browser API
        if(activeStep === 0 && props.surveyDisplay === 'flex'){
            //console.log("Entra")
            props.setMarkerFlag(true)
            setTransport([])
          }else{
            props.setMarkerFlag(false)
          }
          
          
          if(activeStep === 2){
            //setSelections([])
            setIsPositive(undefined)
          }
          
  
          if(activeStep === 7 ){
              console.log('termino')
              uploadFiles()
              const payload = {
                lat: props.chooseLoc[0],
                long: props.chooseLoc[1],
                transportmethods: transport,
                feeling: radioValue,
                features: selections,
                description: description,
                img: 'img'
              } 
        
              //console.log(payload)
        
              setTransport([]);
              setRadioValue('');
              setSelections([]);
              setIsPositive(undefined);
              setDescription('')
              props.setChooseLoc([])
              setFileList([])
            }


    },[activeStep]);
  
  
    return (

    <>

    {/*}
    <Grid item container direction='column' justifyContent="center" alignItems="center" style={{maxWidth:"20vw", paddingLeft:"1vw", paddingTop:"10vh", textAlign: "center"}}>

       <Grid item xs={2} className="surveytitle">
            <Typography>
                PASOS
            </Typography>
       </Grid>

       <Grid item xs={8} container className="surveybody" direction="column"  >
            
            <Grid item className="stepItem" style={{backgroundColor:"white"}}>
                <Typography className='typostyle'>
                    1. Ubica el lugar haciendo clic
                </Typography>
            </Grid>

            <Grid item className="stepItem" >
                <Typography className='typostyle'>
                    2. ¿Cómo llegaste?
                </Typography>
            </Grid>
            
            <Grid item className="stepItem">
                <Typography className='typostyle'>
                    3. ¿Cómo te sientes en este lugar?
                </Typography>
            </Grid>
            
            <Grid item className="stepItem">
                <Typography className='typostyle'>
                    4. Me siento así por
                </Typography>
            </Grid>
            
            <Grid item className="stepItem">
                <Typography className='typostyle'>
                    5. Organiza por prioridad
                </Typography>
            </Grid>
            
            <Grid item className="stepItem">
                <Typography className='typostyle'>
                    6. ¿Tienes algo más que decirnos?
                </Typography>
            </Grid>
            
            <Grid item className="stepItem">
                <Typography className='typostyle'>
                    7. Sube una foto del lugar
                </Typography>
            </Grid>

       </Grid>
       
     

    </Grid> */}

    <Box sx={{ flexGrow: 1 }} >

      <Grid container >

        <Grid item container xs={6} alignContent="baseline" style={{maxWidth:"20vw", paddingLeft:"1vw", paddingTop:"10vh", textAlign: "center"}}>
          
            <Grid item xs={12} className="surveytitle">
                <Typography>
                    {t('enc.title')}
                </Typography>
            </Grid>

            <Grid item xs={12} container className="surveybody" direction="column"  >
            {
                        activeStep !== 7 ? (
                          <>
                <Grid item className="stepItem" style={{backgroundColor: activeStep ===0 ? "white": "#b8e9fa"}}  >
                    <Typography className='typostyle' >
                        1. {t('enc.pr1')}
                    </Typography>
                    
                </Grid>
                
                <Grid item className="stepItem" style={{backgroundColor: activeStep ===1 ? "white": "#b8e9fa"}}  ref={refStep1}>
                    <Typography className='typostyle' >
                        2. {t('enc.pr2')}
                    </Typography>
                </Grid>
                
                <Grid item className="stepItem" style={{backgroundColor: activeStep ===2 ? "white": "#b8e9fa"}} ref={refStep2}>
                    <Typography className='typostyle'>
                        3. {t('enc.pr3')}
                    </Typography>
                </Grid>
            
                <Grid item className="stepItem" style={{backgroundColor: activeStep ===3 ? "white": "#b8e9fa"}} ref={refStep3}>
                    <Typography className='typostyle'>
                        4. {t('enc.pr4')}
                    </Typography>
                </Grid>
                
                <Grid item className="stepItem" style={{backgroundColor: activeStep ===4 ? "white": "#b8e9fa"}} ref={refStep4}>
                    <Typography className='typostyle'>
                      5. {t('enc.pr5')}
                    </Typography>
                </Grid>
                
                <Grid item className="stepItem" style={{backgroundColor: activeStep ===5 ? "white": "#b8e9fa"}} ref={refStep5}>
                    <Typography className='typostyle'>
                        6. {t('enc.pr6')}
                    </Typography>
                </Grid>
                
                <Grid item className="stepItem" style={{backgroundColor: activeStep ===6 ? "white": "#b8e9fa"}} ref={refStep6}>
                    <Typography className='typostyle'>
                        7. {t('enc.pr7')}
                    </Typography>
                </Grid>
              </>
                        ):
                        (
                          <Grid item className="stepItem" style={{backgroundColor: activeStep ===6 ? "white": "#b8e9fa"}} ref={refStep6}>
                            <Typography className='typostyle'  variant="h6" gutterBottom>
                            {t('enc.th')}
                            </Typography>
                            <Typography className='typostyle'>
                            {t('enc.oh')} ☺
                            </Typography>
                      </Grid>
                        )
                        }
            </Grid>

            <Grid item container xs={12} className="surveyFooter">
                <Grid item xs={6}>
                {
                        activeStep !== 7 ? (
                    <Button variant="contained" style={{backgroundColor:"#F0AB00"}}  onClick={backWardStep} 
                            disabled={activeStep === 0 ? true : false }> <ArrowBackIcon/> {t('enc.anterior')} </Button>
                        ):
                        (
                          <Button variant="contained" style={{backgroundColor:"#F0AB00"}}  onClick={(e)=>{setActiveStep(0)}} 
                            > {t('enc.oth')} </Button>
                        )
                      
                      }
                </Grid>

                <Grid item xs={6}>

                    
                    {
                        activeStep !== 7 ? (
                           
                            <Button variant="contained" style={{backgroundColor:"#F0AB00"}}onClick={forwardStep}> {activeStep === 6 ? t('enc.fin') : t('enc.next')} <ArrowForwardIcon/> </Button>
                        )
                        : (
                            <Button variant="contained" style={{backgroundColor: '#F0AB00'}} onClick={handleFinish}>{t('enc.cerrar')}</Button>
                        )
                    }
                
                </Grid>

            </Grid>

          
        
        </Grid>
        
        { (activeStep !== 0 && activeStep !== 7) &&
        <Grid item container xs={6}  >
          
          <Grid item  >
            <Item style={{  marginTop:dynamicHeight, borderColor: 'black', borderStyle:'solid'}}>
                { activeStep === 1 ? (
                    <TransportsRadios handleTransports={handleTransports} transport={
                     transport 
                    }></TransportsRadios>
                ):
                activeStep === 2 ? (
                    <PlaceFeeling radioValue={radioValue} setRadioValue={setRadioValue} setSelections={setSelections}></PlaceFeeling>
                ):
                activeStep === 3 ? (
                    <Box sx={{ maxHeight:"40vh", overflowY:'auto', flexGrow: 1}} >

          
                            <Grid container direction="row" >
                                <Grid item>
                                  <Typography variant="subtitle2" gutterBottom>
                                    {t('enc.pp4')}
                                  </Typography>
                                </Grid>
                                {isPositive === false ? (
                                
                                <Grid item>
                                    
                                    <NegativeQuestion selections={selections} setSelections={setSelections} style={{maxHeight:"100%"}}></NegativeQuestion>
                                </Grid>
                                
                                
                                
                                ) :
                                isPositive === true ? (
                                
                                <>
                                <Grid item>
                                    <PositiveQuestion selections={selections} setSelections={setSelections} ></PositiveQuestion>
                                </Grid>
                                
                                </>
                                ):
                                (
                                <></>
                                )
                                }
                                

                            </Grid>
                        </Box>

                ):
                activeStep === 4 ? (
                    <Box sx={{ maxHeight:"40vh", overflowY:'auto', flexGrow: 1}} >
                      <Grid container direction="row" >
                      <Grid item>
                        <Typography variant="subtitle2" gutterBottom>
                          {t('enc.arr')}
                        </Typography>
                      </Grid>
                      
                      <Grid item style={{color:"black"}} >
                        <ListComponent selections={selections} setSelections={setSelections}></ListComponent>
                      </Grid>
                      </Grid>
                    </Box>
                ):
                activeStep === 5 ? (
                    <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            placeholder={t('enc.more')}
                            value={description}
                            onChange={ev => setDescription(ev.target.value)}
                            style={{ width: '15vw', height:'15vh' }}
                          />
                    
                ):
                activeStep === 6 ? (
                    <Box>
                        { fileList.length === 0 ?
                            (
                              <DropBox onDrop={onDrop} />
                            ) :
                
                            <List>
                              {/* MAPPING THROUGH THE fileList variable to get file's path and render an icon based on its extension */}
                              {fileList.map((d, idx) => (
                                <ListItem
                                  divider
                                  key={idx}
                                  secondaryAction={
                                    <IconButton
                                      edge="end"
                                      onClick={() => deleteFileFromList(d.path)}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  }
                                >
                                  
                                  <ListItemText
                                    primaryTypographyProps={{
                                      overflow: 'hidden',
                                      lineHeight: '18px',
                                      fontSize: 15,
                                    }}
                                    primary={d.path}
                                  />
                                </ListItem>
                              ))}
                            </List>
                
                          }

                    </Box>
                ):
                    <Box >
                        

                    </Box>

                }
                
                
            </Item>
          </Grid>
        
        </Grid>
        }


      </Grid>

    </Box>

    </>
  )
}
