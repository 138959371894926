import React, { useState, useEffect } from 'react'
import { createTheme, useMediaQuery } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Link } from 'react-router-dom';
//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Volver from '../components/Volver';

import { useTranslation } from 'react-i18next';


/*** Logo IMPORT */
import img from '../assets/img2.jpg'
// import top from '../assets/login.PNG'
import top from '../assets/fotografiadron.png'

//Data
import dataUsers from "../data.json"

// API
import { newMapping } from '../api/userAPI';

// Redux
import { deleteInfo } from '../redux/useReducer';
import { padding } from '@mui/system'

import Swal from 'sweetalert2'

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const CssTextField = styled(TextField)({
  // '& label': {
  //   color: 'transparent'
  // },
  '& .css-1wc848c-MuiFormHelperText-root': {
    color: 'red',
    paddingLeft: '2%',
    fontSize: '14px'
  },
  '& label.MuiInputLabel-shrink': {
    color: 'transparent',
  },
  
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});

export default function RegistarMapeo() {

  const { t, i18n } = useTranslation();

  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const navigate = useNavigate();
    const dispatch = useDispatch();

  //States
  const [codigo, setCodigo] = useState('');
  const [titulo, setTitulo] = useState('');
  const [dias, setDias] = useState(0);
  const [open, setOpen] = useState(false);


  const [errorCodigo, setErrorCodigo] = useState('');
  const [errorTitulo, setErrorTitulo] = useState('');
  const [errorDias, setErrorDias] = useState('');

  const [loading, setLoading] = useState(false)


  
  const styles ={
    
    imglogocontainer:{
      width: '100%',
      height: '35vh'
    },
    imglogo:{
      width: '100%',
      height: '100%'
    },
    formContainer: {
      backgroundColor: '#eeca45',
      paddingBottom: '10%',
      paddingTop: '5%',
      color: 'white',
      marginTop: '-20px'
    },
    field: {
      // width: '200%',
      color: 'white',
      backgroundColor: 'white',
      borderRadius: '100px',
      marginBottom: '20px'
    }

    
  }

    //Handler for submit
    const submitHandler = async () => {
    
      setLoading(true)
    //Validation
    if(codigo.length < 4){
      setErrorCodigo('Código incorrecto')
    }
    if(titulo.length < 4){
      setErrorTitulo('El título debe ser de al menos 4 dígitos')
    }
    if(dias < 1){
      setErrorDias('Los días deben ser mayor o igual a uno')
    }

    const dataMapeo = {
        codigo,
        titulo,
        dias
    }
    console.log(dataMapeo)


    try{
      const response = await newMapping(dataMapeo)
       console.log(response)
       Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Mapeo registrado!',
        showConfirmButton: false,
        timer: 1500
      })
      return navigate(`/`)
      }
      catch(error){
        //setMessage({ open: true, msg: error.data.msg })
        setOpen(true)
      }
    

      setLoading(false)
   

    

    }

    // Handle for close
    const handleClose = () => {

        setOpen(false)

    };

    // Handle User
    const handleCodigo = (e) => {

        setCodigo(e.target.value)
        setErrorCodigo('')

    }

    //Handle Password
    const handleTitulo = (e) => {

        setTitulo((e.target.value))
        setErrorTitulo('')

    }

    const handleDias = (e) => {

        setDias((e.target.value))
        setErrorDias('')

    }

  



  return (

    <>
      {/* <div style={styles.imglogocontainer}>
      <img src={top} style={styles.imglogo}/>
    </div> */}
    <Grid container style={{height:"100vh", width: isSm ? "100vw" : "80vw", margin: '0 auto'}} justifyContent="center" alignItems='center'>

      {/** RIGHT CONTAINER */}
      <Grid  item container md={6} 
          // direction="column"
          justifyContent="center"
          // alignItems="center" 
          // spacing={3}
          style={styles.formContainer}
          >
        
        
        <Grid item xs={12} align="center">
          <Typography style={{fontSize: isSm ? '16px' : '24px', fontWeight: 'bold', marginBottom: '20px', color: '#000000'}} > Registrar un nuevo mapeo </Typography>
        </Grid>

        <Grid item xs={10} md={8} >
          <CssTextField
          color='secondary'
          style={styles.field}
          fullWidth
            required
            id="outlined-required"
            label="Código por asignar"
            onChange={handleCodigo}
            value={codigo}
            // error={errorUser !== ''}
            helperText={errorCodigo}
            onKeyPress={(e) => e.key === "Enter" && submitHandler()}


          />
        </Grid>

        <Grid item xs={10} md={8}>
          <CssTextField
          color='secondary'
          style={styles.field}
          fullWidth
            required
            id="outlined-password-input"
            label="Título"
            type="text"
            autoComplete="current-password"
            onChange={handleTitulo}
            value={titulo}
            // error={errorPassword !== ''}
            helperText={errorTitulo}
            onKeyPress={(e) => e.key === "Enter" && submitHandler()}
          />
        </Grid>
        <Grid item xs={10} md={8} >
          <CssTextField
          color='secondary'
          style={styles.field}
          fullWidth
            required
            id="outlined-required"
            label="Días de vigencia"
            type='number'
            onChange={handleDias}
            value={dias}
            // error={errorUser !== ''}
            helperText={errorDias}
            onKeyPress={(e) => e.key === "Enter" && submitHandler()}


          />
        </Grid>
        <Grid item xs={12} textAlign="center" style={{margin: '20px 0'}}>
           <Link to={'/'} style={{fontSize: isSm ? '16px' : '18px'}}>Volver</Link> 
        </Grid>
        <Grid item xs={12} container style={{marginBottom: '20px'}}>
          <Button 
            variant="contained"
            onClick={submitHandler}
            style={{background: 'rgba(0, 112,189,1)', color: 'white', borderRadius: '100px', padding: '10px 30px', margin: '0 auto', fontWeight: 'bold'}}
        >Envíar</Button>
        </Grid>
        {
        loading && (
          <Box sx={{ display: 'flex' }} >
            <CircularProgress />
          </Box>
        )
      }
      </Grid>
      

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        
      >
        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>{t('landing.login.user')}</Alert>
      </Snackbar>

    </Grid>
    <Volver />

    </>
    
  )
}