import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material';

import './draggable-list.css'

import DraggableListItem from './DraggableListItem'

export default function DraggableList(props) {
    
    const data  = props.data;
    const setData = props.setData;
    const [dragStartIndex, setDragStartIndex] = React.useState(null);
    
    //get index of dragged item
    const onDragStart = (index) => setDragStartIndex(index) 

    //update list when item dropped
    const onDrop = (dropIndex) =>{
        //console.log(dropIndex)

        // get dragged item
        const dragItem = data[dragStartIndex]

        // Delete dragged item in list
        let list = [...data]
        list.splice(dragStartIndex,1)

        if(dragStartIndex < dropIndex){
            setData([
                ...list.slice(0 ,dropIndex-1),
                dragItem,
                ...list.slice(dropIndex-1,list.length)
            ])
        }else {
            setData([
                ...list.slice(0 ,dropIndex),
                dragItem,
                ...list.slice(dropIndex,list.length)
            ])
        }

    }

    return (
    <ul className='draggable-list'>
        {
            data.map((item,index)=>(
                <DraggableListItem 
                    key={index}
                    index = {index}
                    onDragStart={(index) => onDragStart(index)}
                    onDrop={(index)=> onDrop(index)}
                >
                    {
                        <Grid container
                        alignItems="center" justifyContent="flex-start" >
                            <Grid item style={{fontSize:"80%"}}>
                                {index+1}
                            </Grid>
                            <Grid item style={{fontSize:"80%"}}>  
                                {props.renderItemContent(item)}
                            </Grid>
                           
                        </Grid>
                      
                    }
                </DraggableListItem>
            ))
        }
        {/** add last  item so you can drag item to last position
         *  last item dont need onDragStart because it can not be dragged 
        */}
        <DraggableListItem
            key={data.length}
            index = {data.length}
            draggable = {false}
            onDrop={(index)=> onDrop(index)}
        ></DraggableListItem>


    </ul>
  )
}

DraggableList.propTypes = {
    data: PropTypes.array,
    renderItemContent: PropTypes.func
}
